import { useEffect, useRef, useState } from 'react';
import { useGetMessagesMutation } from '../../../../redux/login/services/MessagesServiceApi';
import { useLanguage, useTranslate } from '../../../context/languageContext';
import CommentStateElement from './CommentStateElement';

export default function CommentStateScreen(props: any) {
  const [elementsPerPage, setElementsPerPage] = useState(4);
  const [messages, setMessages] = useState([] as any);
  const [selected, setSelected] = useState(0);

  const language = useLanguage();
  const t = useTranslate();
  const listInnerRef = useRef(null);

  const [getMessages] = useGetMessagesMutation();

  useEffect(() => {
    async function fetchData() {
      if (elementsPerPage - 3 <= messages?.data?.pagination?.total || !messages.data)
        setMessages(
          await getMessages({ locale: language, pageNumber: 1, elementsPerPage: elementsPerPage })
        );
    }
    fetchData();
  }, [elementsPerPage]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.round(scrollTop + 1 + clientHeight) >= scrollHeight) {
        setElementsPerPage(elementsPerPage + 4);
      }
    }
  };

  const getType = (element: { planningId: any; stopId: any; lineId: any }) => {
    if (element.planningId) {
      return t('planificationReport');
    } else if (element.stopId) {
      return t('stopReport');
    }
    //TODO: add line
    else if (element.lineId) {
      return t('lineReport');
    } else {
      return t('suggestion');
    }
  };

  const renderAlerts = () => {
    let res = [];
    for (let element in messages?.data?.data)
      if (
        (selected === 2 && messages['data']['data'][element].reply) ||
        (selected === 1 && !messages['data']['data'][element].reply) ||
        selected === 0
      )
        res.push(
          <CommentStateElement
            key={messages['data']['data'][element].id}
            title={messages['data']['data'][element].title}
            type={getType(messages['data']['data'][element])}
            sentAt={messages['data']['data'][element].sentAt}
            messageBody={messages['data']['data'][element].body}
            reply={messages['data']['data'][element].reply}
            replyAt={messages['data']['data'][element].replyAt}
          />
        );
    return res;
  };

  return (
    <div className={'account-child-header'} style={{ width: '100%' }}>
      <div className={'account-support-header'}>
        <div className={'title'}>{t('messageState')}</div>
        <div className={'row'} style={{ overflowX: 'scroll' }}>
          <div
            className={selected == 0 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(0)}>
            {t('all')}
          </div>
          <div
            className={selected == 1 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(1)}>
            {t('inProcess')}
          </div>
          <div
            className={selected == 2 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(2)}>
            {t('answered')}
          </div>
        </div>
      </div>
      <div
        className={'account-child'}
        style={{ maxHeight: 414, minHeight: 414 }}
        onScroll={onScroll}
        ref={listInnerRef}>
        {renderAlerts()}
      </div>
    </div>
  );
}
