import {useEffect, useState} from 'react';
import {useResponsive} from "../../context/responsiveContext";
import FavoriteHeader from "./FavoriteHeader";
import FavoriteBody from "./FavoriteBody";
import {VM} from "../../../viewmodel/VM";


export default function FavoriteModal(props : any) {
    const responsive = useResponsive()
    const [selected, setSelected] = useState(VM.screenSelected.section)

    useEffect(()=>{
        VM.screenSelected.setSection(selected)
    },[selected])

    return (
        <div className={`mapOption ${responsive.isBig ? 'big' : 'small'}`}>
            <FavoriteHeader setSelected={setSelected} selected={selected}/>
            <FavoriteBody selected={selected}/>
        </div>
    );
}
