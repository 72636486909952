import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface lineInformation {
  direction: number;
}

const initialState = {
  lineInformation: {
    direction: null
  },
  allLines: [],
  filteredLine: null,
};

export const lineSlice = createSlice({
  name: 'lineSlice',
  initialState,
  reducers: {
    updateLineDirection: (state, action) => {
      state.lineInformation.direction = action.payload;
    },
    updateAllLines: (state, action) => {
      state.allLines = action.payload;
    },
    updateFilteredLine: (state, action) => {
      state.filteredLine = action.payload;
    },
  }
});

export const lineInformation = (state: RootState) => state.lineSlice.lineInformation;

export const linesState = (state: RootState) => state.lineSlice.allLines;

export const lineFilteredState = (state: RootState) => state.lineSlice.filteredLine;