export const strings = {
  filters: 'Filtros',
  appName: 'Moveuskadi',
  button_delete: 'Borrar selección',
  undo: 'Deshacer',
  remove: 'Borrar',
  explore: 'Explorar',
  saves: 'Guardados',
  openData: 'Open Data',
  downloadApp: 'Descargue la aplicación de moveuskadi para móvil.',
  circulation: 'Circulación',
  account: 'Cuenta',
  login: 'Iniciar sesión',
  login2: 'Inicia sesión',
  incidence: 'Avisos',
  transbord: 'Transbordo',
  stopSaved: 'Has guardado esta parada,',
  saveStopAlert: '¿deseas activar una alerta? ',
  saveAlert: '¿Deseas activar una alerta?',
  activateAlerts: 'Activar alertas',
  noThanks: 'No, gracias',
  saveStopAlertDescription:
    'Activa una alerta para que en caso de que la parada se vea afectada por algún tipo de incidencia, te avisemos.',
  saveLineAlertDescription:
    'Activa una alerta para que en caso de que la línea se vea afectada por algún tipo de incidencia, te avisemos.',
  saveStopAlertBody:
    'Seleccione cuando deseas recibir alertas de servicio en caso de incidencias sobre las paradas guardadas',
  loginToFav: 'Inicia sesión para guardar como favorito',
  loginToReport: 'Inicia sesión para reportar un error',
  loginToFavs: 'Inicia sesión para guardar como favoritos',
  loginToAlerts: 'Inicia sesión para configurar una alerta',
  loginToFavBody: 'Regístrate o inicia sesión para poder acceder a todas las funcionalidades',
  deleteFavConfirm: '¿Deseas eliminar este favorito?',
  register: 'Registrarme',
  myAccount: 'Mi cuenta',
  infoPersonal: 'Información personal',
  whereDoYouGo: '¿A dónde quieres ir?',
  simpleTicket: 'Billete sencillo',
  activeAlert: 'Activar alerta',
  removeAlert: 'Eliminar alerta',
  alertAdded: 'Alerta añadida',
  alertRemoved: 'Alerta eliminada',
  password: 'Contraseña',
  passwordConditions:
    'Para cambiar la contraseña, necesitas un mínimo de 6 dígitos, una mayúscula, una minúscula y un número',
  man: 'Hombre',
  woman: 'Mujer',
  today: 'Hoy',
  exitTo: 'Salidas para',
  another: 'Otro',
  setAlias: 'Establece un alias',
  alias: 'Alias',
  aliasColon: 'Alias:',
  save: 'Guardar',
  share: 'Compartir',
  shareVia: 'Compartir con',
  linkToShare: 'Enlace para compartir',
  copyLink: 'Copiar enlace',
  copiedToClipboard: 'Copiado en portapapeles',
  downloadMobileApp: 'Descarga la aplicación móvil',
  goToAppleStore: 'Ir a Apple Store',
  goToAccount: 'Ir a cuenta',
  goToGooglePlay: 'Ir a Google Play Store',
  noBinary: 'No binario',
  links:'Enlaces',
  ageUnder19: '0-19',
  age20to45: '20-45',
  age45to65: '45-65',
  ageOver65: '>65',
  copyUrl: 'Url copiada',
  changePass: 'Cambiar contraseña',
  change: 'Cambiar',
  manageAccount: 'Gestionar cuenta',
  language: 'Idioma',
  help: 'Ayuda',
  support: 'Soporte y ayuda',
  manageNotifications: 'Gestión de notificaciones',
  news: 'Noticias',
  guided: 'Guiado',
  guidedNotificationsNotice:
    'Si activas esta opción, Moveuskadi te enviará notificaciones de los siguientes pasos y cambios  durante el proceso de guiado.',
  trafficAlerts: 'Alertas de noticias y avisos',
  // trafficAlertsNotificationsNotice: '',
  general: 'General',
  privacy: 'Política de privacidad',
  useConditions: 'Condiciones de uso',
  terms: 'Términos del servicio',
  shareApp: 'Compartir app',
  reviewApp: 'Valorar',
  dataAccount: 'Datos de la cuenta',
  charMaxAndMin: '6 caracteres mínimo y maximo 30 caracteres',
  minNum: 'Al menos un número',
  and: 'y',
  accept: 'Acepto',
  minMayusMin: 'Al menos una letra mayúsculas y minúsculas',
  noMatch: 'No coinciden',
  fillFields: 'Rellena los siguientes campos',
  personalInfo: 'Datos personales',
  createAccount: 'Crear cuenta',

  noStationsSaved:
    'Parece que no tienes ninguna estación o parada guardada en favoritos, escoge aquellas que más uses y guárdala para poder acceder a ella rápidamente.',
  meetABit: 'Para que nos conozcamos un poco',
  noTransportSaved: 'No tienes ningún transporte favorito',
  noTransportSavedBody:
    'Para configurar tus transportes favoritos tendrás que guardarlos en tu cuenta dentro de la sección de transportes favoritos.',
  nameComa: 'Nombre:',
  name: 'Nombre',
  email: 'Email',
  emailLong: 'Correo electrónico',
  userName: 'Nombre de la persona usuaria',
  needANewPass: '¿Necesitas una contraseña nueva?',
  introduceYourEmail: 'Introduce el correo electrónico que usaste al crear tu cuenta.',
  gender: 'Género',
  ageComa: 'Edad:',
  age: 'Edad',
  phone: 'Teléfono',
  website: 'Página web',
  direction: 'Dirección',
  seeAll: 'Ver todas',
  selectAll: 'Seleccionar todos',
  selectAllFavorites: 'Seleccionar Favoritos',
  subscribeNews: 'Suscribirse a noticias',
  selectOpNews:
    'Selecciona los operadores sobre los que quieras recibir notificaciones cuando haya una noticia nueva.',
  lessInfo: 'Ver menos info',
  moreInfo: 'Ver más info',
  goThere: 'Llegar hasta allí',
  notFound: 'No se encontró nada',
  messageState: 'Estado de mis comentarios',
  all: 'Todas',
  inProcess: 'En proceso',
  answered: 'Respondidos',
  checkSearch:
    'Comprueba que la búsqueda esté bien escrita. Prueba a añadir una ciudad, un estado o un código postal.',
  myDirections: 'Mis direcciones',
  directions: 'Direcciones',
  searchByCategory: 'Búsqueda por categorías',
  recents: 'Recientes',
  useMyLocation: 'Mi localización',
  es: 'Castellano',
  eu: 'Euskera',
  en: 'Inglés',
  fr: 'Francés',
  visualizer_filters: 'Filtros',
  visualizer_filtersAll: 'Todos',
  visualizer_filtersBoth: 'Ambos',
  visualizer_moreFilters: 'Más filtros',
  visualizer_message_moreFilters:
    'Selecciona aquellos operadores que más uses y solo se visualizarán esos en el mapa.',
  visualizer_filters_empty: 'No se ha podido cargar ningún filtro, revise su conexión.',
  visualizer_filters_motorized: 'Motorización',
  visualizer_filters_onlySelected: 'Solo uno',
  visualizer_filters_other: 'Otros',
  visualizer_filters_selected: 'seleccionados',
  visualizer_filter_selected: 'seleccionados',
  visualizer_poi: 'Puntos de interés',
  visualizer_ModosdeTransporte: 'Transportes',
  visualizer_operators: 'Operadores',
  systemNotifications: 'Notificaciones del sistema',
  systemNotificationsNews:
    'Si activas esta opción, Moveuskadi te enviará notificaciones sobre las noticias a las que te has suscrito.',
  systemNotificationsAlerts:
    'Si activas esta opción, Moveuskadi te enviará notificaciones sobre las alertas o incidencias de transporte para las líneas, paradas y noticias a las que te has suscrito.',
  systemNotificationsNotice:
    'Si activas esta opción, Moveuskadi te enviará notificaciones sobre cambios importantes y errores en el sistema. Te recomendamos que las mantengas activas.',
  h: 'h',
  min: 'min',
  accessible: 'Accesible',
  exitNow: 'Salir ahora',
  exitAt: 'Salida a las',
  exitAtText: 'Salida',
  accountNoActivated: 'Cuenta no activada',
  accountCreated: 'Cuenta creada. Para activar la cuenta revise su correo.',
  noInternetConnection: 'No hay conexión a internet',
  serviceError: 'Fallo del servicio',
  reviewLoginOrPass: 'Revisa tu correo o contraseña',
  seeRoutes: 'Ver rutas',
  stopTime: 'A que hora vas a salir',
  accountActivated: 'Cuenta activada',
  accountActivatedDes: '¡Tu cuenta se ha creado y activado con éxito!',
  accountNoActivatedDes:
    'Ha ocurrido un problema al crear y activar tu cuenta. Por favor, prueba de nuevo más tarde.',
  arriveAt: 'Llegada',
  preferences: 'Preferencias',
  other: 'Otro',
  confirm: 'Confirmar',
  leaveSuggestion: 'Poner una sugerencia',
  suggestionNotice:
    'Nos esforzamos constantemente por mejorar vuestra experiencia. Por eso, nos gustaría saber que funciones os gustan más y en cuales podríamos mejorar',
  commentStateNotice: 'Podrás ver el estado de todas las sugerencias y los reportes que has puesto',
  report: 'Reportar',
  timeSoonThanBefore: 'Hora inferior a la parada anterior',
  timeSoonThanBeforeBody:
    'La hora seleccionada es anterior a un tiempo de una parada anterior. Por favor, seleccione una hora posterior.',
  reportProblem: 'Reportar una incidencia',
  reportSystemError: 'Reportar un error del sistema',
  reportOperator: 'Reportar un operador',
  reportAnError: 'Reportar un error',
  reportStop: 'Reportar parada',
  reportStopNotice: 'Escoge el motivo por el que deseas reportar la siguiente parada.',
  reportWrongAddress: 'Dirección incorrecta',
  reportStopData: 'Datos de parada incorrectos',
  reportStopState: 'Estado de la parada',
  reportLine: 'Reportar linea',
  reportLineNotice: 'Escoge el motivo por el que deseas reportar la siguiente línea.',
  reportDataError: 'Datos incorrectos',
  reportTimeError: 'Error en tiempos de paso',
  reportPlanning: 'Reportar planificación',
  reportPlanningNotice: 'Escoge el motivo por el que deseas reportar la siguiente planificación.',
  reportDoesntComplyWithPreferences: 'No cumple con las preferencias',
  reportNoResults: 'No hay resultados',
  searchEmptyResults: 'No se han encontrado resultados',
  searchMoreResults: 'Cargar más',
  reportAlternativeRoutes: 'Hay rultas alternativas',
  reportRoute: 'Reportar ruta',
  reportRouteNotice: 'Escoge el motivo por el que deseas reportar la planificación',
  reportIndicationsError: 'Error en las indicaciones',
  sharePlanning: 'Consulta la información de la siguiente ruta',
  leaveComment: 'Pon un comentario',
  sendComment: 'Enviar comentario',
  send: 'Enviar',
  destination: ' Destino',
  origin: 'Origen',
  tomorrow: 'Mañana',
  nextStops: 'Próximas salidas',
  searchADirection: 'Busca una dirección',
  suggestion: 'Sugerencia',
  planificationReport: 'Reporte de planificación',
  stopReport: 'Reporte de parada',
  lineReport: 'Reporte sobre la línea',
  answer: 'Respuesta',
  filterNoResult:
    'Seleccione otro filtro rápido o recalcule la planificación con una nueva configuración.',
  firstExit: 'Primera salida',
  lastExit: 'Última salida',
  component_loading: 'Cargando',
  none: 'Ninguna',
  favs: 'Favoritas',
  allAlerts: 'Todos los avisos',
  lines: 'Líneas',
  transit_lines: 'Líneas',
  transit_stops: 'Paradas',
  start: 'Inicio: ',
  end: 'Fin: ',
  cantAddStops: 'No se pueden añadir más paradas',
  maximumNumberStops: 'Se ha alcanzado el número máximo de paradas.',
  stationsQuant: ' paradas',
  yourLocation: 'Tu localización',
  accessible_routes: 'Rutas Accesibles',
  fav_transports: 'Transportes favoritos',
  fast_routes: 'Rutas más rápidas',
  eco_routes: 'Rutas más económicas',
  transport_modes: 'Modos de transporte',
  monitor_types: 'Tipo de motorización',
  operators: 'Operadores',
  route_types: 'Tipos de rutas',
  fastest_route: 'RUTA MÁS RÁPIDA',
  eco_route: 'RUTA MÁS ECONÓMICA',
  walk_routes: 'Rutas andar menos',
  walk_route: 'RUTA ANDAR MENOS',
  routes: 'Rutas',
  places: 'Lugares',
  ubis: 'Ubicaciones',
  favorites: 'Favoritos',
  stations: 'Paradas',
  stops: 'Paradas',
  stops_route: 'Paradas',
  stop: 'Parada',
  stopSaved2: 'Parada guardada',
  stopRemoved: 'Parada eliminada',
  directionSaved: 'Dirección guardada',
  directionRemoved: 'Dirección eliminada',
  poiSaved: 'Punto de interés guardado',
  poiRemoved: 'Punto de interés eliminado',
  planSaved: 'Itinerario guardado',
  planRemoved: 'Itinerario eliminado',
  stopsAndStations: 'Paradas y estaciones',
  cancel: 'Cancelar',
  next: 'Siguiente',
  myTransport: 'Mi transporte',
  descriptionMyTransport:
    'Selecciona aquellos transportes que más utilices para tener un acceso rápido desde el mapa.',
  forgotPass: 'Olvidé mi contraseña',
  configAlert: 'Configurar alerta',
  pois: 'Puntos de interés',
  logout: 'Cerrar sesión',
  close: 'Cerrar',
  alertDetail: 'Detalle aviso',
  youAreBack: '¡Ya estás de vuelta!',
  alreadyHaveAccount: 'Ya tengo una cuenta',
  errorLogin: 'Error al iniciar sesión',
  notAccount: ' No tengo cuenta',
  myFavTransp: 'Mis transportes favoritos',
  date_day: 'un día',
  date_days: 'días',
  date_difference: 'Hace',
  date_weeks: 'semanas',
  date_hours: 'horas',
  date_minute: 'min',
  minute: 'minuto',
  minutes: 'minutos',
  date_month: 'un mes',
  date_week: 'una semana',
  zero_min: '0 min',
  newsDetails: 'Detalle noticia',
  moreInf: 'Más información',
  seeMore: 'Ver más',
  visit: 'Visitar',
  noNews: 'No se han encontrado noticias',
  edit: 'Editar',
  otherPois: 'Otros puntos de interés',
  delete: 'Eliminar',
  newPass: 'Nueva contraseña',
  oldPass: 'Contraseña antigua',
  passNew: 'Contraseña nueva',
  repeatPassNew: 'Repetir contraseña nueva',
  home: 'Casa',
  work: 'Trabajo',
  add: 'Añadir',
  addHome: 'Añadir casa',
  addWork: 'Añadir trabajo',
  addDirection: 'Añadir dirección',
  update: 'Actualizar',
  setDirection: 'Establece la dirección',
  createAlert: 'Crear alerta',
  trainStation: 'Estacion de tren',
  monday: 'lun.',
  tuesday: 'mar.',
  wednesday: 'mié.',
  thursday: 'jue.',
  friday: 'vie.',
  saturday: 'sab.',
  sunday: 'dom.',
  january: 'ene.',
  february: 'feb.',
  march: 'mar.',
  april: 'abr.',
  may: 'may.',
  june: 'jun.',
  july: 'jul.',
  august: 'ago.',
  september: 'sep.',
  october: 'oct.',
  november: 'nov.',
  december: 'dic.',
  from: 'Desde ',
  until: ' hasta ',
  untilThe: 'Hasta las ',
  at: ' a las ',
  success: 'Success',
  recoveryEmailSent:
    'Si existe un usuario con el mail introducido le enviaremos un mail para realizar la recuperación de contraseña',
  resetData: 'Se han restablecido los datos de cuenta',
  accountDataSaved: 'Datos de cuenta actualizados',
  accountNotRegistered: 'Tu correo no está registrado',
  userExists: 'Ya existe un usuario con este correo',
  activateYourAccount: 'Activa tu cuenta para poder entrar como usuario',
  loginSuccess: 'Has iniciado la sesión correctamente',
  logoutSuccess: 'Has cerrado la sesión correctamente',
  intermediateStop: 'Parada Intermedia ',
  noLineData: 'No tenemos datos de esta línea.',
  stationOf: 'Estación de ',
  stopOf: 'Parada de ',
  removeAccount: 'Borrar cuenta',
  removeAccountNotice:
    '¿Estás seguro de que desea borrar su cuenta de Moveuskadi? En caso de confirmar la baja, perderá toda la información almacenada en el sistema (favoritos, alertas, mensajes etc.) y no se podrá recuperar.',
  walk: 'Andando',
  bus: 'Bus',
  subway: 'Metro',
  funicular: 'Funicular',
  tranvia: 'Tranvia',
  rentalBike: 'Bicicleta de alquiler',
  bike: 'Bicicleta',
  train: 'Tren',
  bikes: 'Bicicletas',
  capacity: 'Espacios',
  markersGroup: 'Grupo de marcadores',
  leaveTitle:'Pon un asunto',
  reportIncidence: 'Reportar un error',
  stopIncidence: 'Incidencia sobre parada',
  lineIncidence: 'Incidencia sobre línea',
  planIncidence: 'Incidencia sobre planificación',
  operatorIncidence: 'Incidencia sobre operador',
  systemIncidence: 'Incidencia sobre sistema/aplicación',
  bikeStation: 'Parada de Bicicletas',
  poiContainer: 'Grupo de puntos de interés',
  stopContainer: 'Grupo de paradas',
  container:'Grupo de marcadores',
  moveInformative:'El contenido de Moveuskadi tiene cáracter exclusivamente Informativo. El proveedor de los datos es el operador de transporte correspondiente. El Gobierno Vasco no responderá de ninguna consecuencía, daño o perjuicio que pudieran derivarse del uso de dicha información.',

  lastUpdatePrivacy: 'Última actualización. 18/03/2023',
  firstParagraphPrivacy: 'La presente Declaración de política de privacidad, aplicada en la aplicación MOVEUSKADI tiene por objetivo principal ayudar a comprender a las personas usuarias nuestros compromisos de protección, tratamiento, retención y uso de los datos sensibles estrictamente necesarios que se le puedan requerir en base a las funcionalidades y servicios ofrecidos por la aplicación, que, de manera voluntaria desee utilizar.',
  secondParagraphPrivacy: '¿Usamos código de terceros?',
  thirdParagraphPrivacy: 'Para formular cualquier duda o consulta sobre nuestra Política de privacidad, está disponible la siguiente dirección de contacto: ',
  fourthParagraphPrivacy: 'Lea atentamente nuestra Política de privacidad antes de utilizar la aplicación MOVEUSKADI. Si no está de acuerdo con los términos descritos en esta Política de privacidad, siempre puede optar por no utilizar las funciones y servicios específicos de la aplicación MOVEUSKADI que requieran datos y permisos adicionales, o dejar de utilizar por completo la aplicación, aun en modo anónimo.',
  firstSubtitle:'Objetivos de la aplicación',
  fiveParagraph: 'El Sistema de información de transporte público MOVEUSKADI está desarrollado por Eusko Jaurlaritza-Gobierno Vasco, con la finalidad de ofrecer contenido informativo acerca de la oferta de transporte público y fomentar su uso.',
  sixParagraph: 'Algunas de las funciones principales de la aplicación son:',
  firstPointList: ['Consulta de horarios de transporte público.',
  'Consulta de paradas de transporte público.',
  'Planificación de rutas a través del transporte público indicando un origen y un destino que pueden ser la propia ubicación de la persona usuaria.',
  'Posibilidad de, una vez planificada una ruta, ejecutar un servicio de guiado para ayudar a la persona usuaria a saber qué pasos tiene que realizar para completar su ruta.',
  'Posibilidad de guardar direcciones o puntos favoritos',
  'Posibilidad de recibir notificaciones y alertas relacionadas con el transporte público.'
  ],
  secondSubtitle: 'Que información recopila y usa MOVEUSKADI',
  sevenParagraph:'Para hacer uso de las funcionalidades y servicios adicionales ofrecidos por MOVEUSKADI que la persona usuaria desee utilizar, la aplicación presentará previamente un mensaje de aviso en el que se le informará claramente del requerimiento de recopilación de los datos y servicios del dispositivo a los que se MOVEUSKADI necesita acceder, y se solicitará su consentimiento expreso para su recopilación y uso. Si se declina dicho consentimiento, esta información no se recopilará ni usará, no pudiendo, por tanto, utilizar la funcionalidad y servicio solicitado.',
  eightParagraph: 'No obstante, aunque MOVEUSKADI cuente con su consentimiento expreso, en cualquier momento podrá revocar, tanto el acceso a los datos personales y sensibles que se hayan recopilado, como el uso de los servicios del dispositivo a los que se otorgó permiso.',
  thirdSubtitle: 'Dirección de correo',
  nineParagraph:'MOVEUSKADI da la posibilidad de crear una cuenta personal única para poder guardar la información de la persona usuaria sobre rutas, direcciones o transportes favoritos. Al configurar una cuenta personal en MOVEUSKADI, únicamente compartirá con nosotros su dirección de correo electrónico.',
  tenParagraph:'En el caso de crear una cuenta personal, MOVEUSKADI también recabará los datos de rango de edad y género, única y exclusivamente con fines estadísticos.',
  elevenParagraph:'Aunque el servicio de MOVEUSKADI está disponible para todas las personas usuarias, configurar una cuenta personal registrada permite acceder además a las siguientes funciones y servicios ofrecidos por la aplicación:',
  secondPointList:['Guardado de favoritos: paradas, puntos de interés, ubicaciones y rutas',
      'Envío de mensajes para reportar errores o mejoras',
      'Suscripción a las alertas de los operadores'],
  fourthSubtitle:'Información de ubicación geográfica',
  twelveParagraph:'MOVEUSKADI puede recopilar información sobre su ubicación (geolocalización) y sobre la ruta o viaje planificado, para ofrecer el acceso a funcionalidades y servicios adicionales. Obtenemos información de:',
  thirdPointList:['Localización: La finalidad de esta información es mostrar opciones de transporte cercanas y planificar rutas con origen o destino la ubicación del dispositivo. Para acceder a esta funcionalidad la persona usuaria debe pulsar voluntariamente sobre el botón de “geolocalización','Localización en segundo plano: La finalidad de esta información es poder habilitar las funciones de guiado sobre una ruta planificada. Se comprueba el lugar donde se encuentra el dispositivo sobre la ruta y se notifica a la persona usuaria de cuándo debe realizar una acción sobre el itinerario (aviso de parada de destino próxima), aunque la aplicación esté cerrada y no se esté usando. MOVEUSKADI recopila los datos de ubicación en fondo, incluso cuando no se está utilizando la aplicación para maximizar la experiencia y ofrecer estos servicios de guiado (aviso de parada de destino próxima). Esta funcionalidad se activa solo en el caso en que la persona usuaria voluntariamente pulse sobre el botón de “guiado”.'],
  thirteenParagraph:'Para obtener la localización, recopilamos la ubicación detallada, incluidas las señales de GPS y otros datos del sensor del dispositivo, como giroscopios/osciloscopios, enviados por su dispositivo móvil en el que está instalado y activado el servicio.',
  fourteenParagraph:'Puede elegir desactivar los servicios de ubicación en cualquier momento y continuar utilizando la aplicación con las funciones que lo no lo requieran.',
  fiveSubtitle:'Notificaciones Push',
  fiveteenParagraph:'Enviar actualizaciones, avisos, anuncios e información adicional relacionada con los servicios de transporte.',
  sixSubtitle: 'Que información comparte MOVEUSKADI',
  sixteenParagraph:'Moveuskadi utilizará los datos recopilados con los siguientes propósitos:',
  fourthPointList:['Ofrecer los servicios indicados en el apartado “Objetivo de la aplicación”.',
  'Personalizar el contenido y personalizar la experiencia.',
  'Permitir que las personas usuarias envíen mensajes sobre errores o mejoras de la aplicación.',
  'Dar servicio de soporte y responder a las consultas realizadas.',
  'Enviar actualizaciones, avisos, anuncios e información adicional relacionada con los servicios de transporte.' +
  'Uso de datos anónimos para fines estadísticos, de mantenimiento, de desarrollo y de mejora de los servicios que ofrece MOVEUSKADI. Las rutas y viajes planificados configurados por las personas usuarias se almacenan de manera anónima, no estando por tanto asociadas en ningún caso a la persona usuaria registrada que lo configuró.'
  ],
  sevenSubtitle:'Seguridad de los datos',
  seventeenParagraph:'La aplicación MOVEUSKADI cumple estrictamente con la normativa europea, estatal y autonómica sobre protección de datos personales.',
  eighteenParagraph:'En cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, se informa que los datos de carácter personal que se recopilen serán tratados e incorporados a la actividad de tratamiento denominada: Sistema de información de transporte público: MOVEUSKADI. ',
  nineteenParagraph:'Las personas usuarias de la aplicación MOVEUSKADI tiene plenamente garantizados los siguientes derechos:',
  fivePointList:['Retirar su consentimiento: Puede revocar en cualquier momento el consentimiento que ha otorgado, sin que esto afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.',
  'Acceso: Puede solicitar el acceso a sus datos personales y a obtener información sobre sus datos de carácter personal sometidos a tratamiento.',
  'Rectificación: Permite corregir errores y modificar los datos que resulten ser inexactos o incompletos.',
  'Supresión (derecho al olvido): Solicitar que los datos sean suprimidos, salvo que exista obligación legal de conservarlos y/o no prevalezcan otros motivos legítimos para su tratamiento. Para ello, podrá seleccionando la opción “Borrar cuenta” dentro de la sección “Gestionar Cuenta” del apartado “Cuenta de la aplicación”.',
  'Limitación: Bajo las condiciones establecida legalmente, podrá solicitar que el tratamiento se paralice, de tal manera que se evite su tratamiento por el responsable quien únicamente los conservará para el ejercicio o la defensa de reclamaciones.',
  'Portabilidad: Podrá recibir sus datos personales que sean de su incumbencia, y transmitirlos directamente a otro responsable en un formato estructura, de uso común y lectura mecánica.',
  'Oposición: En determinadas circunstancias y por motivos relacionados con su situación particular, podrán oponerse al tratamiento de sus datos. En tal caso, el responsable dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.'
  ],
  twentyParagraph:'Los datos personales y sensibles de las personas usuarias recopilados por MOVEUSKADI se mantendrán en el sistema el tiempo estrictamente necesario para cumplir con la finalidad para la que se recabaron y para determinar las posibles responsabilidades que se deriven.',
  firstInfoList:['Dirección de Planificación del Transporte. Departamento de Planificación Territorial, Vivienda y Transportes del Gobierno Vasco',
  'Donostia-San Sebastián, 01010, Vitoria-Gasteiz, Álava',
  'Teléfono +(34) 945 018 000',
  'Página web: '],
  secondInfoList:['Delegada de protección de datos del Gobierno Vasco',
  'Donostia-San Sebastián 1, 01010, Vitoria-Gasteiz, Álava',
  'Teléfono: +(34) 945 018 680',
  'Página web: '],
  twentyoneParagraph:'Asimismo, cuando considere que no ha obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Agencia Vasca de Protección de Datos, cuyos datos de contacto son los siguientes:',
  thirdInfoList:['Agencia Vasca de Protección de Datos',
  'C/ Beato Tomás de Zumárraga, 71, 3ª Planta',
  '01008 Vitoria – Gasteiz',
  'Tel. + (34) 945 016 230',
  'Fax. + (34) 945 016 231',
  'e-Mail: '
  ],
  twentytwoParagraph:'Puede obtener información detallada y extensa sobre los aspectos relativos a la seguridad de los datos y a la protección de datos personales aplicados en MOVEUSKADI en las siguientes páginas:',
  forthInfoList:['Normativa sobre protección de datos personales:',
    'Protección de datos personales del Sistema de información de transporte público MOVESUKADI:',
    'Actividad de tratamiento “Sistema de información de transporte público: MOVEUSKADI”: ',
  ],
  forthInfoListLinks:['https://www.euskadi.eus/normativa-proteccion-de-datos-personales/web01-a2datuba/es/ ',
    'https://www.euskadi.eus/clausulas-informativas/web01-sedepd/es/transparencia/158400-capa2-es.shtml',
    'https://www.euskadi.eus/clausulas-informativas/web01-sedepd/es/transparencia/158400-capa3-es.shtm',
  ],
  visualizer_filter_markers_pois: 'Ocultar puntos de interés',
  stopSchedule: 'Horario de paradas',
  maxWalkDistance_title: 'Tiempo máximo andando',
  maxWalkDistance_placeholder: 'Tiempo en minutos',
  menu_lines_search: 'Búsqueda de línea',
  menu_line_filter_empty: 'No se ha encontrado ninguna línea.',
  menu_line_selector_empty: 'No se ha seleccionado ningún operador.',
  select_operator_placeholder: 'Selecciona un operador',
  menu_lines: 'Listado de líneas',
  transit_news: 'Noticias y avisos',
  incidence_line: 'Avisos Línea',
  incidence_stop: 'Avisos Parada',
  filters_newsTransit_operators: 'Filtrar operadores',
  filters_newsTransit_operators_desc: 'Selecciona los operadores para los que quieres ver noticias y avisos.',
  stop_time_real_late: 'minutos de retraso',
  stop_time_real_ahead: 'minutos de adelanto',
  stop_time_real_late_min: 'minuto de retraso',
  stop_time_real_ahead_min: 'minuto de adelanto',
  stop_time_real_theory: 'Programado',
  favorites_empty_plansMessage:
    'Parece que no tienes ningún itinerario guardado, añade aquellos trayectos que hagas con frecuencia y guárdalos para acceder de manera rápida.',
  favorites_empty_poisMessage:
    'Parece que no tienes ningún punto de interés guardado, añade aquellos puntos que te interesen o que quieras tener acceso rápido.',
  goToFav: 'Ir a favoritos',
  subscriptions_empty_linesMessage:
    'Parece que no tienes ninguna línea de transporte con las alertas activadas, activa las alertas de aquellas que te generen mayor interés.',
  subscriptions_empty_newsMessage:
    'Parece que no tienes ninguna agencia de noticias con las alertas activadas, activa las alertas de aquellas que te generen mayor interés.',
  subscriptions_empty_stopsMessage:
    'Parece que no tienes ninguna parada con las alertas activadas, activa las alertas de aquellas que te generen mayor interés.',
  affected_lines: 'Líneas afectadas',
  affected_stops: 'Paradas afectadas',
  fares: 'Tarifas',
  lines_near_stop: 'Líneas Cercanas a esta parada',
  lines_near_marker: 'Líneas Cercanas',
  stop_time_real_in_time: 'A tiempo',
  visualizer_closestStopsLines: 'Paradas y líneas cercanas',
};
