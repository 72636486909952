import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLanguage, useTranslate } from './languageContext';
import { useGetDataOriginMutation, useGetTransportModesMutation, useLazyGetAgenciesQuery } from '../../redux/login/services/AgencyService';
import { useSelector } from 'react-redux';
import { accountInformation } from '../../redux/slices/authSlice';
import { agencyInformation, agencySlice } from '../../redux/slices/agencySlice';
import FilterUtils from '../../utils/FilterUtils';
import { useGetDateNewsQuery } from '../../redux/login/news/NewsServiceApi';
import { newsSlice } from '../../redux/slices/newsSlice';

export default function AgencyPreload({children}) {
  const dispatch = useDispatch();
  const locale = useLanguage();
  const t = useTranslate
  const [getTransportModes] = useGetTransportModesMutation();
  const [getDataOrigin] = useGetDataOriginMutation();
  const [getLazyAgencies] = useLazyGetAgenciesQuery();
  const account = useSelector(accountInformation);
  const agencyInfo = useSelector(agencyInformation);
  const transportModes = agencyInfo.transportModes;

    const getAgenciesFilter = useCallback(
        (agencies, dataOrigin) => {
          let operators = [];
          for (let i = 0; i < transportModes.length; i++) {
            let modeChanged = JSON.parse(JSON.stringify(transportModes[i]));
            let agenciesMode = [];
            agenciesMode.push({
              id: 0,
              agencyOriginId: 0,
              mode: modeChanged.label,
              modeId: modeChanged.id,
              selected: false,
              icon: modeChanged.iconId,
              shortName: modeChanged.label,
            });
            for (let j = 0; j < dataOrigin.length; j++) {
              let indexMode = dataOrigin[j].transportModes.findIndex(
                (mode) => mode.transportModeId === transportModes[i].id,
              );
              let indexAgency =
                indexMode !== -1
                  ? agencies.findIndex(
                      (agency) => agency.id === dataOrigin[j].agencyId,
                    )
                  : -1;
              let isInAgencies = agenciesMode.find(
                (element) => element.id === dataOrigin[j].agencyId,
              );
    
              let isGrouped = false;
              //en caso de pertenecer a un grupo
              if (dataOrigin[j]?.groupId) {
                let indexAgencyGrouped = agenciesMode.findIndex(
                  (element) => element?.groupId === dataOrigin[j]?.groupId,
                );
                isGrouped = indexAgencyGrouped !== -1;
    
                //si se ha creado el grupo la añadimos al campo de registro de ids del grupo
                if (isGrouped) {
                  let groupOfIdsAgency = JSON.parse(
                    JSON.stringify(agenciesMode[indexAgencyGrouped].agencyIdsGroup),
                  );
    
                  groupOfIdsAgency.push(dataOrigin[j].id);
    
                  agenciesMode[indexAgencyGrouped].agencyIdsGroup =
                    groupOfIdsAgency;
                }
              }
    
              if (
                indexMode !== -1 &&
                !isInAgencies &&
                indexAgency !== -1 &&
                !isGrouped
              ) {
                let agency = JSON.parse(JSON.stringify(agencies[indexAgency]));
                agency.selected = false;
                agency.agencyOriginId = dataOrigin[j].id;
                agency.mode = transportModes[i].label;
                agency.modeId = transportModes[i].id;
                agency.groupId = dataOrigin[j]?.groupId;
                agency.shortName = dataOrigin[j]?.groupTxt
                  ? dataOrigin[j]?.groupTxt
                  : agency.shortName;
                agency.longName = dataOrigin[j]?.groupTxt
                  ? dataOrigin[j]?.groupTxt
                  : agency.longName;
                agency.icons = dataOrigin[j].transportModes[indexMode];
                //en caso de haber grupo añadimos el campo de grupo de ids de agencia de origen
                if (agency.groupId) {
                  agency.agencyIdsGroup = [dataOrigin[j].id];
                  agency.icon = dataOrigin[j].groupIcon;
                }
                agenciesMode.push(agency);
              }
            }
            modeChanged.agencies = agenciesMode;
            operators.push(modeChanged);
          }
    
          return operators;
        },
        [transportModes],
      );
    
      /**
       * Obtiene los modos de transporte llamando a la api y los
       * setea en redux
       */
      useEffect(() => {
        async function getTransportModesApi() {
          const result = await getTransportModes({locale: locale});
    
          if (result.data) {
            dispatch(agencySlice.actions.updateTransportModes(result.data));
          }
        }
    
        if (account.token) {
          getTransportModesApi();
        }
      }, [
        account.token,
        account.tokenDevice,
        dispatch,
        getTransportModes,
        locale,
      ]);
    
      useEffect(() => {
        async function getApi() {
          const resultAgencies = getLazyAgencies({locale: locale});
          const resultDataOrigin = getDataOrigin({locale: locale});
    
          await Promise.all([resultAgencies, resultDataOrigin])
            .then(async resultPromise => {
              if (resultPromise[0].data && resultPromise[1].data) {
                dispatch(
                  agencySlice.actions.updateDataOrigin(resultPromise[1].data),
                );
                const agenciesCombined = FilterUtils.combineValues(
                  resultPromise[0].data,
                  resultPromise[1].data,
                );
    
                //en caso de eliminar un id de origen de agencia, eliminarlo de la lista 
                let selectedOriginsUpdated = agencyInfo.selectedOrigin.filter(
                  (element) =>
                    agenciesCombined.find(
                      (agency) =>
                        String(agency.agencyOriginId) === String(element),
                    ),
                );
    
                if (
                  selectedOriginsUpdated.length !== agencyInfo.selectedOrigin.length
                ) {
                  dispatch(
                    agencySlice.actions.updateSelectedOrigin(
                      selectedOriginsUpdated,
                    ),
                  );
                }
    
                dispatch(
                  agencySlice.actions.updateAgenciesTransport(agenciesCombined),
                );
                let agenciesOfFilters = getAgenciesFilter(
                  JSON.parse(JSON.stringify(resultPromise[0].data)),
                  JSON.parse(
                    JSON.stringify(resultPromise[1].data),
                  )
                );
    
                if (agenciesOfFilters.length > 0) {
                  dispatch(
                    agencySlice.actions.updateAgenciesFilters(agenciesOfFilters),
                  );
                }
              }
            })
            .catch(e => {
              //TO DO
            });
        }
        if (account.token) {
          getApi();
        }
      }, [
        account.token,
        dispatch,
        getAgenciesFilter,
        getDataOrigin,
        getLazyAgencies,
        locale,
      ]); 
      
  const { data: newsDate } = useGetDateNewsQuery(
    account.token,
    {
      pollingInterval: 60000,
    }
  );

  //Actualización información nuevas noticias    
  useEffect(() => {
      dispatch(newsSlice.actions.updateDateNews(newsDate?.result));
  }, [newsDate, account.token])

  return (<></>)
}
