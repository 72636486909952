import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import GeneralUtils from '../../../utils/GeneralUtils';
import { StatusCodesServer } from '../../hooks';
import { eventsModalstoggleVisibilitySlice } from '../../slices/eventsModalstoggleVisibilitySlice';
import { RootState } from '../../store';

export interface Post {
  id: number;
  name: string;
}

export const MessageServiceApi = createApi({
  reducerPath: 'MessageServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accountInformation.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),

  endpoints: (builder) => ({
    getSystemMessages: builder.mutation<any, any>({
      query: (getSystemMessages: {
        locale: string;
        pageNumber: number;
        elementsPerPage: number;
      }) => ({
        url: `/messages/?locale=${getSystemMessages.locale}&pagenumber=${getSystemMessages.pageNumber}&elementsperpage=${getSystemMessages.elementsPerPage}`,
        method: 'GET',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          await queryFulfilled;
          // StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),

    getMessages: builder.mutation<any, any>({
      query: (getSystemMessages: {
        locale: string;
        pageNumber: number;
        elementsPerPage: number;
      }) => ({
        url: `/messages/sent/user?locale=${getSystemMessages.locale}&pagenumber=${getSystemMessages.pageNumber}&elementsperpage=${getSystemMessages.elementsPerPage}`,
        method: 'GET',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),

    postMessages: builder.mutation<any, any>({
      query: (reportData) => ({
        url: '/messages/sent/user',
        method: 'POST',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        },
        body: reportData
      }),

      async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),

    postStopReport: builder.mutation<any, any>({
      query: (reportData) => ({
        url: '/messages/stop',
        method: 'POST',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        },
        body: reportData
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),

    postLineReport: builder.mutation<any, any>({
      query: (reportData) => ({
        url: '/messages/line',
        method: 'POST',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        },
        body: reportData
      }),

      async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),

    postPlanningReport: builder.mutation<any, any>({
      query: (reportData) => ({
        url: '/messages/planning',
        method: 'POST',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        },
        body: reportData
      }),

      async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    })
  })
});

export const {
  useGetSystemMessagesMutation,
  useGetMessagesMutation,
  usePostMessagesMutation,
  usePostStopReportMutation,
  usePostLineReportMutation,
  usePostPlanningReportMutation
} = MessageServiceApi;
