import React, { useEffect, useRef, useState } from 'react';
import { useResponsive } from '../../context/responsiveContext';
import { useTranslate } from '../../context/languageContext';
import { R } from '../../R';
import { VM } from '../../../viewmodel/VM';
import TransitNewsSections from './components/TransitNewsSections';
import OperatorsNewsTransitSelector from './components/OperatorsNewsTransitSelector';
import { useSelector } from 'react-redux';
import { favFilterNewsAlertsState, favoritesInformation, favoritesSlice } from '../../../redux/slices/favoritesSlice';
import { useFavoriteUtils } from '../../../utils/FavoriteUtils';
import { useDispatch } from 'react-redux';
import { newsSlice } from '../../../redux/slices/newsSlice';
import { useLazyGetFiltersNewsAlertsQuery } from '../../../redux/login/favorite/FavoriteServiceApi';
import { agencyInformation } from '../../../redux/slices/agencySlice';
import { useUpdateDateNewsMutation } from '../../../redux/login/news/NewsServiceApi';
import { accountInformation } from '../../../redux/slices/authSlice';

export default function TransitNewsScreen() {
  const responsive = useResponsive();
  const t = useTranslate();
  const Close = R.drawables.general.Ic_close;
  const [agencySelected, setAgencySelected] = useState<any>();
  const favFilterNewsAlerts = useSelector(favFilterNewsAlertsState);
  const favorites = useSelector(favoritesInformation).agencyId;
  const {selectAgenciesToFilterByFav} = useFavoriteUtils();
  const dispatch = useDispatch();
  const [initFavFilters, setInitFavFilters] = useState(false);
  const [initObtFilters, setInitObtFilters] = useState(false);
  const [getFiltersNewsAlerts] = useLazyGetFiltersNewsAlertsQuery();
  const agencyInfo = useSelector(agencyInformation);
  const [editNewsDate] = useUpdateDateNewsMutation();
  const account = useSelector(accountInformation);

  useEffect(() => {
    async function obtainFilters() {
      const result: any = await getFiltersNewsAlerts();

      if (result?.data) {
        let copyResFilters: Array<number> = JSON.parse(JSON.stringify(result?.data?.agencies));
        if (agencyInfo.dataOrigin.length && copyResFilters?.length) {
          copyResFilters.forEach((agency: number) => {
            let foundIndes = agencyInfo.dataOrigin.findIndex((element: any) => element.agencyId === agency);
            if (foundIndes === -1) {
              //se ha eliminado la agencia
              let indexDelete = copyResFilters.indexOf(agency);
              copyResFilters.splice(indexDelete, 1);
            }
          });
        }
        dispatch(favoritesSlice.actions.updateFilterNewsAlerts(result?.data?.favSelected));
        dispatch(favoritesSlice.actions.updateSelectAllFilterNewsAlerts(result?.data?.allSelected));
        dispatch(
          newsSlice.actions.updateFilterAgenciesNewsAlerts(copyResFilters)
        );
      }
      setInitObtFilters(true);
    }
    obtainFilters();
  }, [agencyInfo]);

  useEffect(() => {
    async function updateNewsAlertsDate() {
      await editNewsDate({}).then(() => {
        dispatch(newsSlice.actions.updateDateNews(false));
      });
    }
    updateNewsAlertsDate();
  }, [account.token])

  const styles = {
    content: {
      width: 400
    },
    row: {
      display: 'flex',
      paddingInline: 16,
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  };

  useEffect(() => {
    /* if (favFilterNewsAlerts) {
      dispatch(newsSlice.actions.updateFilterAgenciesNewsAlerts(selectAgenciesToFilterByFav(true)));
    } */
    setInitFavFilters(true);
  }, [favFilterNewsAlerts, favorites]);

  return (
    <div className={`mapOption ${responsive.isBig ? 'big' : 'small'}`}>
      <div className={'transit transit-header'}>
        <div className={'row'}>
          <div className={'title white'}>{t('transit_news')}</div>
          <Close
            className={'close-white'}
            onClick={() => {
              VM.screenSelected.setSelected('explore');
            }}
          />
        </div>
      </div>
      <OperatorsNewsTransitSelector
        agencySelected={agencySelected}
        setAgencySelected={setAgencySelected}
      />
      {initFavFilters && initObtFilters ? <TransitNewsSections /> : null}
    </div>
  );
}
