import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Apis } from '../../../../../../domain/Apis';
import { useGetLineDirectionsQuery } from '../../../../../../redux/login/line/lineServiceApi';
import {
  useAddSubscriptionsMutation,
  useDeleteSubscriptionsMutation
} from '../../../../../../redux/login/subscriptions/SubscriptionsServiceApi';
import { accountInformation } from '../../../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { lineSlice } from '../../../../../../redux/slices/lineSlice';
import {
  subscriptionsInformation,
  subscriptionsSlice
} from '../../../../../../redux/slices/subscriptionsSlice';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import { VM } from '../../../../../../viewmodel/VM';
import Button from '../../../../../components/commons/Button';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import DefaultModal from '../../../../../components/widget/Modals/DefaultModal';
import { useTranslate } from '../../../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../../../context/modalContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';

export default function LineMidInfo(props) {
  const theme = useTheme();
  const t = useTranslate();
  const responsive = useResponsive();
  const account = useSelector(accountInformation);
  const content = useRef(null);
  const [showLineDirections, setShowLineDirections] = useState(false);
  const dispatch = useDispatch();
  const subscriptions = useSelector(subscriptionsInformation);
  const [addSubscription] = useAddSubscriptionsMutation();
  const [deleteSubscription] = useDeleteSubscriptionsMutation();
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const [lineStopsList, setLineStopsList] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const { data: directionData, error: directionError } = useGetLineDirectionsQuery(
    props.lineSelected.id ?? null
  );
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowLineDirections(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    async function fetchStopsList() {
      setLineStopsList(
        await Apis.lines.getStops(
          props.lineSelected.id,
          props.lineSelected.tripId,
          props.lineSelected.stopId
        )
      );
    }
    fetchStopsList();
  }, [props.lineSelected]);

  useEffect(() => {
    let mounted = true;
    async function getFromApi() {
      dispatch(lineSlice.actions.updateLineDirection([]));

      if (directionData && mounted) {
        dispatch(lineSlice.actions.updateLineDirection(directionData));
      }
      if (directionError && mounted) {
        dispatch(lineSlice.actions.updateLineDirection([]));
      }
    }

    getFromApi();

    return () => {
      mounted = false;
    };
  }, [dispatch, directionData, directionError, props.lineSelected, lineStopsList]);

  const updateSubs = () => {
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));
    if (subscriptions.lines.indexOf(props.lineSelected.id) >= 0) {
      updateSubscriptions.lines.splice(updateSubscriptions.lines.indexOf(props.lineSelected.id), 1);
      deleteSubscription({
        stops: [],
        lines: [props.lineSelected.id],
        agencyNotices: []
      });
      dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAlertRemovedModalWidget());
    } else {
      if (account.idUserType === 1) {
        setOpen(true);
        setChild(
          <DefaultModal
            title={t('loginToAlerts')}
            description={t('loginToFavBody')}
            alter={true}
            firstButton={t('createAccount')}
            secondButton={t('login')}
            onClickFirst={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                  true
                )
              );
            }}
            onClickSecond={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
              );
            }}
          />
        );
      } else {
        setOpen(true);
        setChild(
          <DefaultModal
            title={t('saveAlert')}
            description={t('saveLineAlertDescription')}
            secondButton={t('activateAlerts')}
            firstButton={t('noThanks')}
            onClickFirst={() => {}}
            onClickSecond={() => {
              updateSubscriptions.lines.push(props.lineSelected.id);
              addSubscription({
                stops: [],
                lines: [props.lineSelected.id],
                agencyNotices: []
              });
              dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
            }}
          />
        );
      }
    }
  };

  const styles = {
    row: {
      display: 'flex',
      width: '100%',
      transition: 'all 300ms linear',
      alignItems: responsive.isMobile ? 'flex-start' : 'center',
      gap: responsive.isMobile ? 8 : 0,
      flexDirection: responsive.isMobile ? 'column' : 'row'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 5,
      marginBottom: 10,
      width: '100%'
    }
  };
  const openAccessDeniedModal = () => {
    setOpen(true);
    setChild(
      <DefaultModal
        title={t('loginToReport')}
        description={t('loginToFavBody')}
        alter={true}
        firstButton={t('createAccount')}
        secondButton={t('login')}
        onClickFirst={() => {
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
              true
            )
          );
        }}
        onClickSecond={() => {
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
          );
        }}
      />
    );
  };
  const goToReportLineModal = () => {
    dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportModalWidget({
          showModal: true,
          element: props.lineSelected.id,
          type:'line'
        })
    );
  };
  const onShare = () => {
    let path = window.location.href.split('/');
    path.pop();
    path = path.join('/');
    let copy = `${path[path.length - 1] === '#' ? '' : '/#'}/line?${GeneralUtils.convertToHex(
      `id=${props.lineSelected.id}&stopId=${props.lineSelected.stopId}&tripId=${props.lineSelected.tripId}`
    )}`;
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityShareModalWidget({
        showModal: true,
        shareLink: path + copy,
        title: props.lineSelected.code + ', ' + props.lineSelected.name
      })
    );
    // setTimeout(ReactTooltip.hide, 2000);
    // navigator.clipboard.writeText(path + copy);
  };

  return (
    <div style={props.style}>
      <div style={styles.separator} />
      <div style={{ ...styles.row }}>
        <Button
          icon={R.drawables.general.Ic_Reportar}
          text={t('reportAnError')}
          textColor={theme.colors.darkGray}
          iconSize={20}
          onClick={account.idUserType === 2 ? goToReportLineModal : openAccessDeniedModal}
          iconColor={theme.colors.darkGray}
          borderColor={theme.colors.border}
        />
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            width: responsive.isMobile ? '100%' : 'auto',
            justifyContent: 'left'
          }}>
          <ButtonIcon
            icon={
              subscriptions.lines.indexOf(props.lineSelected.id) < 0
                ? R.drawables.commons.notifications
                : R.drawables.general.Ic_AlertaBlue
            }
            iconSize={15}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.border}
            background={theme.colors.white}
            onClick={updateSubs}
          />
          {/* <ReactTooltip event="focusin" effect={'solid'} id="main" /> */}

          <ButtonIcon
            style={{ marginLeft: 10 }}
            onClick={() => onShare()}
            icon={R.drawables.commons.share}
            iconSize={15}
            dataTip={t('copyUrl')}
            dataFor={'main'}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.border}
            background={theme.colors.white}
          />

          {/* <div style={{ zIndex: 999, position: 'absolute', top: 0, left: 0, background: 'red' }}>
            <h3>Share Modal</h3>
          </div> */}
          <div style={{ position: 'relative' }}>
            <ButtonIcon
              style={{ marginLeft: 10 }}
              icon={R.drawables.commons.direction}
              onClick={() => setShowLineDirections(!showLineDirections)}
              iconSize={17}
              iconColor={theme.colors.black}
              borderColor={theme.colors.border}
              background={theme.colors.white}
            />
            {directionData && showLineDirections ? (
              <div
                ref={wrapperRef}
                className="line-direction"
                style={{
                  width: !responsive.isBig ? 200 : 300,
                  left: responsive.isMobile ? 0 : 'auto'
                }}>
                {directionData.map((item) => {
                  return (
                    <p
                      key={item.headSign}
                      style={{
                        wordBreak: 'break-word',
                        fontWeight:
                          props.lineSelected &&
                          item.headSign === lineStopsList[lineStopsList.length - 1].name
                            ? 'bold'
                            : 'normal',
                        cursor:
                          props.lineSelected &&
                          item.headSign === lineStopsList[lineStopsList.length - 1].name
                            ? 'auto'
                            : 'pointer'
                      }}
                      onClick={() => {
                        if (
                          props.lineSelected &&
                          item.headSign === lineStopsList[lineStopsList.length - 1].name
                        ) {
                          
                        } else {
                          VM.lines.setSelected(
                            props.lineSelected.id,
                            item.tripId,
                            props.lineSelected.stopId
                          );
                          setShowLineDirections(false);
                        }
                      }}>
                      {item.headSign}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
