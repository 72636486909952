import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { VM } from '../../../../../../viewmodel/VM';
import { agencyInformation } from '../../../../../../redux/slices/agencySlice';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { linesState } from '../../../../../../redux/slices/lineSlice';
import FilterUtils from '../../../../../../utils/FilterUtils';
import { Apis } from '../../../../../../domain/Apis';
import Loading from '../../../../../components/commons/Loading';
import { sortLines } from '../../../../../../utils/LineUtils';
import { IMarkerInfo } from '../../../../../../types/ExploreIntefaces';
import LineIcon from '../stopDetail/LineIcon';
import { ILine } from '../../../../../../types/LineInterfaces';
import { useTheme } from '../../../../../context/themeContext';
import useStopsUtilities from '../../../../../../utils/StopsUtilities';

interface StopsLinesNearMarkerProps {
  latitude: number;
  longitude: number;
  banStop?: any;
  onClickLine: Function;
  onClickStop: Function;
}

export default function StopsLinesNearMarker(props: StopsLinesNearMarkerProps) {
  const allStops = VM.stops.noMap;
  //await VM.icons.getIcon(agency?.icon)
  const agencyInfo = useSelector(agencyInformation);
  const agencies = agencyInfo.agenciesTransport;
  const t = useTranslate();
  const dispatch = useDispatch();
  const allLines = useSelector(linesState);
  const [renderStops, setRenderStops] = useState<Array<any>>([]);
  const [loading, setIsloading] = useState(true);
  const locale = useLanguage();
  const theme = useTheme();
  const {sortNearStops} = useStopsUtilities();

  const stopsClose: Array<any> = useMemo(() => {
    if (allStops.length > 0 && agencies.length > 0) {
      let stopsFiltered: Array<any> = allStops?.filter((element: any) => {
        return (
          FilterUtils.calculateRegion(
            parseFloat(element.latitude),
            parseFloat(element.longitude),
            props.latitude,
            props.longitude
          ) < 300
        );
      });

      if (props.banStop) {
        let existsStop = stopsFiltered?.findIndex(
          (element: any) => String(element.id) === String(props.banStop?.id)
        );
        if (existsStop !== -1) {
          stopsFiltered.splice(existsStop, 1);
        }
      }

      return sortNearStops(stopsFiltered);
    }

    return [];
  }, [agencies, allStops, props.latitude, props.longitude, props.banStop]);

  useEffect(() => {
    async function obtainLinesStops() {
      let iconsToGet: Array<any> = [];
      let promisesStops = await Promise.all(
        stopsClose.map(async (pointMarker: any) => {
          const resultLines: any = await Apis.stops.getStopLines(locale, pointMarker.id);
          //caso renfe media distancia no mostrar
          const dataOri: any = agencyInfo.dataOrigin.find(
            (element: any) => String(element.id) === String(pointMarker?.agencyOriginId)
          );

          //añadir iconos
          let agencySel: any;
          const transportSel: any = agencyInfo.agenciesFilters.find((element: any) => {
            if (element?.id === pointMarker?.transportMode) {
              return true;
            }
            return false;
          });

          if (transportSel) {
            agencySel = transportSel?.agencies?.find((element: any) => {
              if (element?.groupId) {
                if (element?.agencyIdsGroup?.includes(pointMarker?.agencyOriginId)) {
                  return true;
                }
              } else {
                return String(pointMarker?.agencyOriginId) === String(element?.agencyOriginId);
              }
            });
          }

          let foundIconTransp = iconsToGet.find(
            (element: any) => String(element) === String(transportSel?.iconId)
          );
          if (!foundIconTransp && transportSel?.iconId) {
            iconsToGet.push(transportSel?.iconId);
          }

          let foundIconAg = iconsToGet.find(
            (element: any) => String(element) === String(agencySel?.icon)
          );
          if (!foundIconAg && agencySel?.icon) {
            iconsToGet.push(agencySel?.icon);
          }

          if (dataOri?.label && !dataOri?.label?.toLowerCase()?.includes('renfe media distancia')) {
            return {
              ...pointMarker,
              ...{
                lines: resultLines,
                transportIcon: transportSel?.iconId,
                agencyIcon: agencySel?.icon
              }
            };
          }
          return {
            ...pointMarker,
            ...{ transportIcon: transportSel?.iconId, agencyIcon: agencySel?.icon }
          };
        })
      );
      await Promise.all(
        iconsToGet.map(async (icon: any) => {
          return await VM.icons.getIcon(icon);
        })
      );
      setRenderStops(promisesStops);
      setIsloading(false);
    }

    if (stopsClose?.length) {
      obtainLinesStops();
    } else {
      setIsloading(false);
    }
  }, [agencyInfo, stopsClose]);

  const renderLinesRepeated = ({ item, index, stop }: { item: any; index: number; stop: any }) => {
    if (!item?.code) {
      return;
    }

    return (
      <div
        key={item?.id + '-' + index}
        onClick={() => {
          return props.onClickLine(item.id, stop);
        }}>
        <LineIcon
          color={item.routeColor ? `#${item.routeColor}` : null}
          textColor={item.routeTextColor ? `#${item.routeTextColor}` : null}
          text={item.code}
        />
      </div>
    );
  };

  function renderStopsClose({ item }: { item: any }) {
    let agencySel: any;
    const transportSel: any = agencyInfo.agenciesFilters.find((element: any) => {
      if (element?.id === item.transportMode) {
        return true;
      }
      return false;
    });

    if (transportSel) {
      agencySel = transportSel?.agencies?.find((element: any) => {
        if (element?.groupId) {
          if (element?.agencyIdsGroup?.includes((item as IMarkerInfo).agencyOriginId)) {
            return true;
          }
        } else {
          return String((item as IMarkerInfo).agencyOriginId) === String(element?.agencyOriginId);
        }
      });
    }
    let linesOfItem = item?.lines ?? [];
    let sortedLines = sortLines(
      JSON.parse(JSON.stringify(linesOfItem)).map((line: ILine) => {
        let foundLine = allLines.find((element: any) => element.id === line.id);

        if (foundLine) {
          return foundLine;
        }
        return { code: '' };
      })
    );
    const IconTransport = VM.icons.data[item?.transportIcon];
    const IconAgency = VM.icons.data[item?.agencyIcon];

    return (
      <div
        key={item.id}
        style={{borderBottom: `1.5px solid ${theme.colors.border}`, paddingBottom: 4, marginTop: 4, cursor: 'pointer'}}
        onClick={() => {
          return props.onClickStop(item);
        }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4 }}>
          {item?.transportIcon ? (
            <img style={{ width: 20, height: 20 }} src={IconTransport} />
          ) : null}
          {item?.agencyIcon ? <img style={{ width: 20, height: 20 }} src={IconAgency} /> : null}
          {agencySel ? (
            <div
              style={{
                fontSize: 13,
                fontWeight: 500,
                color: 'rgb(96, 96, 96)'
              }}>
              {agencySel?.longName}
            </div>
          ) : null}
        </div>
        <div
          style={{
            fontSize: 14,
            marginTop: 4,
            textAlign: 'left',
            fontWeight: 'bold'
          }}>
          {item?.name}
        </div>
        {sortedLines ? (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              flexWrap: 'wrap',
              gap: 4,
              marginTop: 4,
            }}>
            {sortedLines?.map((element: any, index: number) => {
              return renderLinesRepeated({
                item: element,
                index: index,
                stop: item
              });
            })}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            //height: Dimensions.get('window').height * 0.5,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: 8
            //overflow: 'scroll'
          }}>
        {/* <div style={{ textAlign: 'left', fontWeight: '600', fontSize: 14 }}>{t('Paradas Cercanas')}</div> */}
          {renderStops?.length ? (
            renderStops?.map((element: any) => {
              return renderStopsClose({ item: element });
            })
          ) : (
            <div>{t('visualizer_closestStops_empty')}</div>
          )}
        </div>
      )}
    </div>
  );
}
