import { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { Apis } from '../../../../../../domain/Apis';
import { VM } from '../../../../../../viewmodel/VM';
import Alert from '../../../../../components/commons/Alert';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import LineIcon from '../stopDetail/LineIcon';
import LineMidInfo from './LineMidInfo';
import LineSynoptic from './LineSynoptic';
import GeneralUtils from "../../../../../../utils/GeneralUtils";
import TimeUtils from '../../../../../../utils/TimeUtils';

export default function LineDetail(props) {
  const theme = useTheme();
  const CloseIcon = R.drawables.general.Ic_close;
  const Arrow = R.drawables.commons.right;
  const responsive = useResponsive();
  const t = useTranslate();
  const language = useLanguage();

  const [stopTimes, setStopTimes] = useState();
  const [rangeTimes, setRangeTimes] = useState();
  const [stops, setStops] = useState();
  const [operatorIcon, setOperatorIcon] = useState();

  useEffect(() => {
    setStopTimes(null);
    setStops(null);
    setRangeTimes(null);
    async function fetchData() {
      setStopTimes(
        await Apis.lines.getStopTimes(
          props.lineSelected.id,
          props.lineSelected.tripId,
          props.lineSelected.stopId
        )
      );
      setStops(
        await Apis.lines.getStops(
          props.lineSelected.id,
          props.lineSelected.tripId,
          props.lineSelected.stopId
        )
      );
      setRangeTimes(
        await Apis.lines.getRangeTimes(props.lineSelected.id, language, props.lineSelected.stopId)
      );
    }
    fetchData();
    let copy = `${GeneralUtils.getBaseUrl()}/#/line?${GeneralUtils.convertToHex(
        `id=${props.lineSelected.id}&stopId=${props.lineSelected.stopId}&tripId=${props.lineSelected.tripId}`
    )}`;
    window.history.pushState('a','a', copy)
  }, [props.lineSelected]);

  useEffect(() => {
    async function fetchData() {
      setOperatorIcon(await VM.icons.getIcon(props.lineSelected?.agencyOriginId, 'agency'));
    }
    fetchData();
  }, [props.lineSelected]);

  const styles = {
    content: {
      position: 'absolute',
      display: 'flex',
      marginInline: !responsive.isBig ? '25px' : '105px',
      marginTop: '25px',
      width: '80vw',
      maxWidth: '368px',
      minWidth: '232px',
      maxHeight: window.innerHeight - 60,
      flexDirection: 'column',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '12px'
    },
    subContent: {
      paddingInline: '12px',
      textAlign: 'left',
      alignContent: 'center'
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center'
    },
    iconSup: {
      marginRight: 5,
      width: 10,
      fill: props.lineSelected?.routeColor ? '#' + props.lineSelected.routeColor : theme.colors.main
    },
    direction: {
      fontSize: 12,
      wordBreak: 'break-all'
    },
    verticalSeparator: {
      height: 20,
      width: 1,
      border: `0.5 solid ${theme.colors.border}`,
      backgroundColor: theme.colors.border,
      margin: 10
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 10,
      marginBottom: 5,
      width: '100%'
    },
    closeIcon: {
      marginRight: 5,
      fill: theme.colors.black,
      cursor: 'pointer',
      marginLeft: 'auto',
      display: 'flex'
    }
  };

  const renderAlerts = () => {
    let res = [];
    let alerts = props.lineSelected?.alert;
    for (var alert in alerts) {
      res.push(
        <Alert
          key={alerts[alert].idAlerta + alert}
          style={{ margin: 5 }}
          title={alerts[alert]?.titulo}
          description={alerts[alert]?.descripcion}
        />
      );
    }
    return res;
  };

  return (
    <div style={styles.content}>
      <div style={{ padding: 12 }}>
        <CloseIcon
          style={styles.closeIcon}
          fill={theme.colors.black}
          onClick={() => props.onClose()}
        />
        {props.lineSelected ? (
          <div style={styles.subContent}>
            <div style={styles.row}>
              <LineIcon icon={operatorIcon} style={{ marginRight: 2 }} />
              <LineIcon
                color={props.lineSelected?.routeColor}
                textColor={'#' + VM.lines.data[props.lineSelected?.id]?.routeTextColor}
                text={VM.lines.data[props.lineSelected?.id]?.code}
                style={{ marginRight: 5 }}
              />
              <div style={{ wordBreak: 'break-word' }}>{props.lineSelected?.name}</div>
            </div>
            <div
              style={{
                ...styles.row,
                ...{
                  ...{
                    marginLeft: 55,
                    flexDirection: 'column',
                    alignItems: 'start',
                    paddingLeft: 8
                  }
                }
              }}>
              <Arrow style={{ ...styles.iconSup }} />
              <div style={styles.direction}>
                {stopTimes ? stopTimes[stopTimes.length - 1]?.nameStop : ''}
              </div>

              <div style={styles.direction}>
                {stopTimes ? JSON.stringify(stopTimes.length) + t('stationsQuant') : ''}
              </div>
            </div>
            {renderAlerts()}
          </div>
        ) : (
          <ClipLoader size={20} />
        )}
        <div style={styles.separator} />

        <div style={{ ...styles.row, ...{ justifyContent: 'left', fontSize: 14 } }}>
          <div style={{ flex: 2, textAlign: 'left' }}>{t('firstExit')}</div>
          <div style={{ flex: 2, textAlign: 'center' }}>{rangeTimes?.firstDep ? TimeUtils.adaptFormatTo24Hours(rangeTimes?.firstDep) : ''}</div>
          <div style={{ flex: 1 }}>
            <div style={styles.verticalSeparator} />
          </div>
          <div style={{ flex: 2, textAlign: 'center' }}>{t('lastExit')}</div>
          <div style={{ flex: 2, textAlign: 'right' }}>{rangeTimes?.lastDep ? TimeUtils.adaptFormatTo24Hours(rangeTimes?.lastDep) : ''}</div>
        </div>

        <LineMidInfo lineSelected={props.lineSelected} markerSelected={props.markerSelected} />
      </div>

      <LineSynoptic
        style={{ borderRadius: '0 0 12px 12px' }}
        line={props.lineSelected}
        stop={props.stop}
        stops={stops}
        stopTimes={stopTimes}
        onClickMarker={props.onClickMarker}
        setMarkerSelected={props.setMarkerSelected}
      />
    </div>
  );
}
