export const drawables = {
  commons: {
    appIcon: require('./drawables/mipmaps/appIcon.svg').ReactComponent,
    appIconLong: require('./drawables/mipmaps/appIconLong.svg').ReactComponent,
    appIconShort: require('./drawables/mipmaps/appIconShort.svg').ReactComponent,
    bar: require('./drawables/common/bar.svg').ReactComponent,
    threePoints: require('./drawables/common/threePoints.svg').ReactComponent,
    work: require('./drawables/common/work.svg').ReactComponent,
    home: require('./drawables/common/home.svg').ReactComponent,
    pencil: require('./drawables/common/pencil.svg').ReactComponent,
    rightArrow: require('./drawables/common/rightArrow.svg').ReactComponent,
    leftArrow: require('./drawables/common/leftArrow.svg').ReactComponent,
    back: require('./drawables/common/back.svg').ReactComponent,
    close: require('./drawables/common/close.svg').ReactComponent,
    selectedRadio: require('./drawables/common/selectedRadio.svg').ReactComponent,
    unselectedRadio: require('./drawables/common/unselectedRadio.svg').ReactComponent,
    down: require('./drawables/common/down.svg').ReactComponent,
    up: require('./drawables/common/up.svg').ReactComponent,
    phone: require('./drawables/common/phone.svg').ReactComponent,
    web: require('./drawables/common/web.svg').ReactComponent,
    star: require('./drawables/common/star.svg').ReactComponent,
    share: require('./drawables/common/share.svg').ReactComponent,
    notifications: require('./drawables/common/notifications.svg').ReactComponent,
    exclaimation: require('./drawables/common/exclaimation.svg').ReactComponent,
    direction: require('./drawables/common/direction.svg').ReactComponent,
    right: require('./drawables/common/right.svg').ReactComponent,
    time: require('./drawables/common/time.svg').ReactComponent,
    plus: require('./drawables/common/plus.svg').ReactComponent
  },

  map: {
    location: require('./drawables/map/location.svg').ReactComponent,
    circulation: require('./drawables/map/circulation.svg').ReactComponent,
    goThere: require('./drawables/map/goThere.svg').ReactComponent,
    gps: require('./drawables/map/gps.svg').ReactComponent,
    markers: {
      DirectionMarker: require('./drawables/general/directionMarker.svg').default,
      DirectionMarkerSel: require('./drawables/general/directionMarkerSel.svg').default,
      HomeMarker: require('./drawables/general/homeMarker.svg').default,
      HomeMarkerSel: require('./drawables/general/homeMarkerSel.svg').default,
      WorkMarker: require('./drawables/general/workMarker.svg').default,
      WorkMarkerSel: require('./drawables/general/workMarkerSel.svg').default,
      pin_aeropuerto: require('./drawables/map/markers/pin_aeropuerto.png'),
      pin_farmacia: require('./drawables/map/markers/pin_farmacia.png'),
      pin_general: require('./drawables/map/markers/pin_general.png'),
      pin_hospital: require('./drawables/map/markers/pin_hospital.png'),
      pin_museo: require('./drawables/map/markers/pin_museo.png'),
      origin: require('./drawables/map/markers/origin.png'),
      my_location: require('./drawables/map/markers/my_location.png'),
      destination: require('./drawables/map/markers/destination.png'),
      0: require('./drawables/map/markers/1.png'),
      1: require('./drawables/map/markers/2.png'),
      2: require('./drawables/map/markers/3.png')
    },
    containers: {
      2: require('./drawables/map/markers/Ic_container2.png'),
      3: require('./drawables/map/markers/Ic_container3.png'),
      4: require('./drawables/map/markers/Ic_container4.png'),
      5: require('./drawables/map/markers/Ic_container5.png'),
      6: require('./drawables/map/markers/Ic_container6.png'),
      7: require('./drawables/map/markers/Ic_container7.png'),
      more: require('./drawables/map/markers/Ic_containerMore.png'),

    }
  },

  account: {
    user: require('./drawables/account/user.svg').ReactComponent,
    favorite: require('./drawables/account/favorite.svg').ReactComponent,
    help: require('./drawables/account/help.svg').ReactComponent,
    language: require('./drawables/account/language.svg').ReactComponent,
    manageAccount: require('./drawables/account/manageAccount.svg').ReactComponent,
    manageNotifications: require('./drawables/account/manageNotifications.svg').ReactComponent
  },

  providers: {
    appleStore: require('./drawables/providers/appleStore.svg').ReactComponent,
    AppleStoreBlack: require('./drawables/providers/AppStoreBlack.svg').ReactComponent,
    euskadiLogo: require('./drawables/providers/euskadiEus.svg').ReactComponent,
    googlePlay: require('./drawables/providers/googlePlay.svg').ReactComponent,
    GooglePlayBlack: require('./drawables/providers/GooglePlayBlack.svg').ReactComponent,
    gobiernoVasco: require('./drawables/providers/gobierno-vasco.svg').ReactComponent,
    financiado: require('./drawables/providers/financiado.svg').ReactComponent,
    //gobiernoVasco2: require('./drawables/providers/3.png'),
    financiado2: require('./drawables/providers/plan2.png'),
    plan2: require('./drawables/providers/financiado2.png')
  },

  about: {},

  poi: {
    I_Biblioteca: require('./drawables/poi/I_Biblioteca.svg').ReactComponent,
    Ic_Airplane: require('./drawables/poi/Ic_Airplane.svg').ReactComponent,
    Ic_Building: require('./drawables/poi/Ic_Building.svg').ReactComponent,
    Ic_Comercio: require('./drawables/poi/Ic_Comercio.svg').ReactComponent,
    Ic_Deporte: require('./drawables/poi/Ic_Deporte.svg').ReactComponent,
    Ic_Electrolinera2: require('./drawables/poi/Ic_Electrolinera2.svg').ReactComponent,
    Ic_Gasolinera: require('./drawables/poi/Ic_Gasolinera.svg').ReactComponent,
    Ic_hospital: require('./drawables/poi/Ic_hospital.svg').ReactComponent,
    Ic_Hotel: require('./drawables/poi/Ic_Hotel.svg').ReactComponent,
    Ic_Institucion: require('./drawables/poi/Ic_Institución.svg').ReactComponent,
    Ic_Naturaleza: require('./drawables/poi/Ic_Naturaleza.svg').ReactComponent,
    Ic_Institution: require('./drawables/poi/Ic_Institution.svg').ReactComponent,
    Ic_parkingsquare: require('./drawables/poi/Ic_parking-square.svg').ReactComponent,
    Ic_Restaurantes: require('./drawables/poi/Ic_Restaurantes.svg').ReactComponent
  },

  transport: {
    Ic_patinete: require('./drawables/transport/Ic_ patinete.svg').ReactComponent,
    Ic_Bike: require('./drawables/transport/Ic_Bike.svg').ReactComponent,
    /* Ic_Bus1: require('./drawables/transport/Ic_Bus1.svg').ReactComponent, */
    Ic_Bus: require('./drawables/transport/Ic_Bus.svg').ReactComponent,
    Ic_BusInterurbano: require('./drawables/transport/Ic_BusInterurbano.svg').ReactComponent,
    Ic_BusUrbano: require('./drawables/transport/Ic_BusUrbano.svg').ReactComponent,
    Ic_Car1: require('./drawables/transport/Ic_Car-1.svg').ReactComponent,
    Ic_Car: require('./drawables/transport/Ic_Car.svg').ReactComponent,
    Ic_Combinado1: require('./drawables/transport/Ic_Combinado-1.svg').ReactComponent,
    Ic_Combinado2: require('./drawables/transport/Ic_Combinado-2.svg').ReactComponent,
    Ic_Combinado: require('./drawables/transport/Ic_Combinado.svg').ReactComponent,
    Ic_Ferry: require('./drawables/transport/Ic_Ferry.svg').ReactComponent,
    Ic_Funicular: require('./drawables/transport/Ic_Funicular.svg').ReactComponent,
    Ic_metro: require('./drawables/transport/Ic_metro.svg').ReactComponent,
    Ic_metro_m: require('./drawables/general/Ic_metro.png'),

    Ic_moto: require('./drawables/transport/Ic_moto.svg').ReactComponent,
    Ic_Puente: require('./drawables/transport/Ic_Puente.svg').ReactComponent,
    Ic_Taxi1: require('./drawables/transport/Ic_Taxi-1.svg').ReactComponent,
    Ic_Taxi: require('./drawables/transport/Ic_Taxi.svg').ReactComponent,
    Ic_Tranvia: require('./drawables/transport/Ic_Tranvia.svg').ReactComponent,
    Ic_Tren1: require('./drawables/transport/Ic_Tren-1.svg').ReactComponent,
    Ic_Tren: require('./drawables/transport/Ic_Tren.svg').ReactComponent,
    Ic_VTC: require('./drawables/transport/Ic_VTC.svg').ReactComponent
  },
  general: {
    Ic_Acount: require('./drawables/general/Ic_Acount.svg').ReactComponent,
    Ic_add: require('./drawables/general/Ic_add.svg').ReactComponent,
    Ic_Ajustes: require('./drawables/general/Ic_Ajustes.svg').ReactComponent,
    Ic_Alerta1: require('./drawables/general/Ic_Alerta-1.svg').ReactComponent,
    Ic_Alerta2: require('./drawables/general/Ic_Alerta-2.svg').ReactComponent,
    Ic_Alerta: require('./drawables/general/Ic_Alerta.svg').ReactComponent,
    Ic_arrowdown: require('./drawables/general/Ic_arrow-down.svg').ReactComponent,
    Ic_arrowleft: require('./drawables/general/Ic_arrow-left.svg').ReactComponent,
    Ic_arrowright: require('./drawables/general/Ic_arrow-right.svg').ReactComponent,
    Ic_arrowup: require('./drawables/general/Ic_arrow-up.svg').ReactComponent,
    Ic_Bien: require('./drawables/general/Ic_Bien.svg').ReactComponent,
    Ic_bookmark1: require('./drawables/general/Ic_bookmark-1.svg').ReactComponent,
    Ic_bookmark: require('./drawables/general/Ic_bookmark.svg').ReactComponent,
    Ic_BusStop: require('./drawables/general/Ic_BusStop.svg').ReactComponent,
    Ic_calendar: require('./drawables/general/Ic_calendar.svg').ReactComponent,
    Ic_CandadoClose: require('./drawables/general/Ic_Candado-Close.svg').ReactComponent,
    Ic_CandadoOpen: require('./drawables/general/Ic_Candado-Open.svg').ReactComponent,
    Ic_Capasdelmapa: require('./drawables/general/Ic_Capas del mapa.svg').ReactComponent,
    Ic_check: require('./drawables/general/Ic_check.svg').ReactComponent,
    Ic_ChevronDownSmall: require('./drawables/general/Ic_Chevron-Down--Small.svg').ReactComponent,
    Ic_chevrondown: require('./drawables/general/Ic_chevron-down.svg').ReactComponent,
    Ic_ChevronLeftSmall: require('./drawables/general/Ic_Chevron-Left--Small.svg').ReactComponent,
    Ic_chevronleft: require('./drawables/general/Ic_chevron-left.svg').ReactComponent,
    Ic_ChevronRightSmall: require('./drawables/general/Ic_Chevron-Right--Small.svg').ReactComponent,
    Ic_chevronright: require('./drawables/general/Ic_chevron-right.svg').ReactComponent,
    Ic_ChevronUpSmall: require('./drawables/general/Ic_Chevron-Up--Small.svg').ReactComponent,
    Ic_chevronup: require('./drawables/general/Ic_chevron-up.svg').ReactComponent,
    Ic_Circled_Info1: require('./drawables/general/Ic_Circled_Info-1.svg').ReactComponent,
    Ic_Circled_Info: require('./drawables/general/Ic_Circled_Info.svg').ReactComponent,
    Ic_Circled_Question: require('./drawables/general/Ic_Circled_Question.svg').ReactComponent,
    Ic_clock: require('./drawables/general/Ic_clock.svg').ReactComponent,
    Ic_close: require('./drawables/general/Ic_close.svg').ReactComponent,
    Ic_delete: require('./drawables/general/Ic_delete.svg').ReactComponent,
    Ic_download: require('./drawables/general/Ic_download.svg').ReactComponent,
    Ic_Drag: require('./drawables/general/Ic_Drag.svg').ReactComponent,
    Ic_edit: require('./drawables/general/Ic_edit.svg').ReactComponent,
    Ic_email: require('./drawables/general/Ic_email.svg').ReactComponent,
    Ic_export: require('./drawables/general/Ic_export.svg').ReactComponent,
    Ic_eyeoff: require('./drawables/general/Ic_eye-off.svg').ReactComponent,
    Ic_eye: require('./drawables/general/Ic_eye.svg').ReactComponent,
    Ic_favorite1: require('./drawables/general/Ic_favorite-1.svg').ReactComponent,
    Ic_favorite: require('./drawables/general/Ic_favorite.svg').ReactComponent,
    Ic_filter: require('./drawables/general/Ic_filter.svg').ReactComponent,
    Ic_Google: require('./drawables/general/Ic_Google.svg').ReactComponent,
    Ic_home: require('./drawables/general/Ic_home.svg').ReactComponent,
    Ic_idioma: require('./drawables/general/Ic_idioma.svg').ReactComponent,
    Ic_Link: require('./drawables/general/Ic_Link.svg').ReactComponent,
    Ic_location1: require('./drawables/general/Ic_location-1.svg').ReactComponent,
    Ic_location: require('./drawables/general/Ic_location.svg').ReactComponent,
    Ic_logout: require('./drawables/general/Ic_log out.svg').ReactComponent,
    Ic_lookmap: require('./drawables/general/Ic_look map.svg').ReactComponent,
    Ic_map: require('./drawables/general/Ic_map.svg').ReactComponent,
    ic_micro: require('./drawables/general/ic_micro.svg').ReactComponent,
    Ic_motor: require('./drawables/general/Ic_motor.svg').ReactComponent,
    Ic_Gasolinera: require('./drawables/general/Ic_Gasolinera.svg').ReactComponent,
    Ic_Electrolinera2: require('./drawables/general/Ic_Electrolinera2.svg').ReactComponent,
    Ic_Mute: require('./drawables/general/Ic_Mute.svg').ReactComponent,
    Ic_Muymal: require('./drawables/general/Ic_Muy  mal.svg').ReactComponent,
    Ic_MuyBien: require('./drawables/general/Ic_Muy Bien.svg').ReactComponent,
    Ic_Noticias: require('./drawables/general/Ic_Noticias.svg').ReactComponent,
    Ic_NotificacionComentarios: require('./drawables/general/Ic_Notificación - Comentarios.svg')
      .ReactComponent,
    Ic_NotificacionNoticias: require('./drawables/general/Ic_Notificación - Noticias.svg')
      .ReactComponent,
    Ic_NotificacionSistema: require('./drawables/general/Ic_Notificación - Sistema.svg')
      .ReactComponent,
    Ic_Notificacion1: require('./drawables/general/Ic_Notificación-1.svg').ReactComponent,
    Ic_Notificacion: require('./drawables/general/Ic_Notificación.svg').ReactComponent,
    Ic_notificationoff: require('./drawables/general/Ic_notification-off.svg').ReactComponent,
    Ic_optionshorizontal: require('./drawables/general/Ic_options-horizontal.svg').ReactComponent,
    Ic_optionsvertical: require('./drawables/general/Ic_options-vertical.svg').ReactComponent,
    Ic_Parada: require('./drawables/general/Ic_Parada.svg').ReactComponent,
    Ic_Phone: require('./drawables/general/Ic_Phone.svg').ReactComponent,
    Ic_PlanificarDown: require('./drawables/general/Ic_Planificar - Down.svg').ReactComponent,
    Ic_PlanificarLeft: require('./drawables/general/Ic_Planificar - Left.svg').ReactComponent,
    Ic_PlanificarRight: require('./drawables/general/Ic_Planificar - Right.svg').ReactComponent,
    Ic_PlanificarUp: require('./drawables/general/Ic_Planificar - Up.svg').ReactComponent,
    Ic_PointDestino: require('./drawables/general/Ic_Point-Destino.svg').ReactComponent,
    Ic_PointOtro: require('./drawables/general/Ic_Point-Otro.svg').ReactComponent,
    Ic_PointUbi: require('./drawables/general/Ic_Point-Ubi.svg').ReactComponent,
    Ic_Precio: require('./drawables/general/Ic_Precio.svg').ReactComponent,
    Ic_Privacy: require('./drawables/general/Ic_Privacy.svg').ReactComponent,
    Ic_Regular: require('./drawables/general/Ic_Regular.svg').ReactComponent,
    Ic_remove: require('./drawables/general/Ic_remove.svg').ReactComponent,
    Ic_Reportar: require('./drawables/general/Ic_Reportar.svg').ReactComponent,
    Ic_Rutas: require('./drawables/general/Ic_Rutas.svg').ReactComponent,
    Ic_search: require('./drawables/general/Ic_search.svg').ReactComponent,
    Ic_send: require('./drawables/general/Ic_send.svg').ReactComponent,
    Ic_sentido: require('./drawables/general/Ic_sentido.svg').ReactComponent,
    Ic_Separador: require('./drawables/general/Ic_Separador.svg').ReactComponent,
    Ic_silladeruedas: require('./drawables/general/Ic_silla de ruedas.svg').ReactComponent,
    Ic_SocialShare: require('./drawables/general/Ic_Social Share.svg').ReactComponent,
    Ic_Ticket: require('./drawables/general/Ic_Ticket.svg').ReactComponent,
    Ic_Trafico1: require('./drawables/general/Ic_Tráfico-1.svg').ReactComponent,
    Ic_Trafico: require('./drawables/general/Ic_Tráfico.svg').ReactComponent,
    Ic_Triangle_Exclaimation1: require('./drawables/general/Ic_Triangle_Exclaimation-1.svg')
      .ReactComponent,
    Ic_Triangle_Exclaimation: require('./drawables/general/Ic_Triangle_Exclaimation.svg')
      .ReactComponent,
    Ic_Ubicacion: require('./drawables/general/Ic_Ubicación.svg').ReactComponent,
    Ic_user1: require('./drawables/general/Ic_user-1.svg').ReactComponent,
    Ic_user: require('./drawables/general/Ic_user.svg').ReactComponent,
    Ic_Volume: require('./drawables/general/Ic_Volume.svg').ReactComponent,
    Ic_walking: require('./drawables/general/Ic_walking.svg').ReactComponent,
    Ic_WorkSmall: require('./drawables/general/Ic_Work--Small.svg').ReactComponent,
    Ic_train_bus: require('./drawables/general/Ic_train_bus.svg').ReactComponent,
    Ic_train_favs: require('./drawables/general/Ic_train_favs.svg').ReactComponent,
    Ic_Notificación_Alert: require('./drawables/general/Ic_Notificación_Alert.svg').ReactComponent,
    Ic_Chat1: require('./drawables/general/Ic_Chat1.svg').ReactComponent,
    Ic_Mensajes: require('./drawables/general/Ic_Mensajes.svg').ReactComponent,
    Ic_Check_Circle: require('./drawables/general/Ic_Check_Circle.svg').ReactComponent,
    Ic_Answered: require('./drawables/general/Ic_Answere.svg').ReactComponent,
    Ic_favorite_blue: require('./drawables/general/Ic_favorite_blue.svg').ReactComponent,
    Ic_AlertaBlue: require('./drawables/general/Ic_AlertBlue.svg').ReactComponent,
    Ic_login: require('./drawables/general/Ic_login.svg').ReactComponent,
    Ic_myTransport: require('./drawables/general/Ic_myTransport.svg').ReactComponent,
    Ic_myTransportNo: require('./drawables/general/Ic_myTransportNo.svg').ReactComponent,
    Ic_myTransportSel: require('./drawables/general/Ic_myTransportSel.svg').ReactComponent,
    Ic_myTransportYes: require('./drawables/general/Ic_myTransportYes.svg').ReactComponent,
    Ic_parking_square: require('./drawables/general/Ic_parking-square.png')
  }
};
