import React, { useEffect, useRef, useState } from 'react'
import { VM } from '../../../../viewmodel/VM';
import { R } from '../../../R';
import { useTheme } from '../../../context/themeContext';
import { Collapse } from 'react-collapse';

export interface IDropdownItem {
    label: string;
    value: string;
    iconId?: any;
}

export interface DropdownStyledProps {
    ref?: any;
    placeholder?: string;
    colorText?: string;
    items: Array<IDropdownItem>;
    children?: any;
    onChange?: any;
    firstDisabled?: any;
    iconSize?: number;
    styleCollapse?: any;
    selectedItem?: IDropdownItem;
  }

export default function DropdownStyled(props: DropdownStyledProps) {
    const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(props.selectedItem?.value ?? 0) as any;
  const theme = useTheme();
  const dropdownRef = useRef() as any;

  const Up = R.drawables.commons.up;
  const Down = R.drawables.commons.down;

  useEffect(() => {
    setSelected(props.selectedItem?.value ?? 0);
  }, [props.selectedItem])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);

    return () => {
      return document.removeEventListener('mousedown', handleClickComponent);
    }
  }, []);

  const handleClickComponent = (event: any) => {
    if (dropdownRef && !dropdownRef.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const renderItems = () => {
    let res = [] as any;
    if (props?.items) {
      //Primera opción disabled
      if (props.firstDisabled) {
        props.items[0] = props.firstDisabled;
      }
    }

    for (let item of props?.items) {
      let Icon;
      if (item?.iconId) {
        Icon = VM.icons.data[item?.iconId];
      }
      res.push(
        <div
          className={'content-item'}
          style={props.styleCollapse}
          key={item.value}
          onClick={() => {
            if (selected !== item?.value) {
              setSelected(item?.value);
              props.onChange(item?.value);
            }
            setOpened(false);
          }}>
          <div className={'item'} style={{...{backgroundColor: theme.colors.primaryMedium, color: theme.colors.white}, ...item.value === selected ? {fontWeight: 'bold', backgroundColor: theme.colors.primaryDark} : {}}}>
            {Icon ? (
              <img
                style={{ width: props.iconSize ?? 20, height: props.iconSize ?? 20 }}
                src={Icon}
              />
            ) : null}
            {item?.label ? item?.label : ''}
          </div>
          {/* <div className={'divider'} /> */}
        </div>
      );
    }
    return res;
  };

  let selectedItem = props.items.find((element: any) => element?.value === selected);
  let IconSel;
  if (selected && selectedItem && selectedItem?.iconId) {
    IconSel = VM.icons.data[selectedItem?.iconId];
  }
  return (
    <div className={'dropdown'} ref={dropdownRef} >
      <div
        className={'dropdown-title'}
        style={props.colorText ? { color: props.colorText } : opened ? { color: 'black' } : {}}
        onClick={() => setOpened(!opened)}>
        <div style={{ flexDirection: 'row', columnGap: 8, alignItems: 'center', display: 'flex', color: theme.colors.white }}>
          {IconSel ? (
            <img style={{ width: props.iconSize ?? 20, height: props.iconSize ?? 20 }} src={IconSel} />
          ) : null}
          {selectedItem ? selectedItem?.label : props.placeholder}
        </div>

        {opened ? (
          <Up style={{ fill: theme.colors.white, marginLeft: 'auto', paddingRight: 4, width: 40 }} />
        ) : (
          <Down
            style={{ fill: theme.colors.white, marginLeft: 'auto', paddingRight: 4, width: 40 }}
          />
        )}
      </div>
      <div className={'dropdown-content'}>
        <Collapse isOpened={opened}>
          {props?.children ? <div className={'children'}>{props?.children}</div> : renderItems()}
        </Collapse>
      </div>
    </div>
  )
}
