import { useLanguage, useTranslate } from '../view/context/languageContext';
import { authSlice } from './slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from './slices/eventsModalstoggleVisibilitySlice';

export function StatusCodesServer(dispatch: any, statusCode: number) {
  dispatch(
    eventsModalstoggleVisibilitySlice.actions.toggleVisibilityStatusCodesModalWidget({
      code: statusCode,
      boolean: true
    })
  );
}

export async function onLoginMakeUseCase(dispatch: any, queryFulfilled: any) {
  dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
  try {
    const result = await queryFulfilled;

    dispatch(authSlice.actions.setUserMe(result.data));
    dispatch(authSlice.actions.updatIdUserType(2));
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(false));
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityStatusCodesModalWidget({
        code: 200,
        boolean: true
      })
    );

    dispatch(authSlice.actions.updateUseUserMeQuerySkip(false));
  } catch (err: any) {
    StatusCodesServer(dispatch, err.error.status);
  } finally {
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
  }
}

export function calculateDifferenceHours( hoursNow: number, hoursOriginal: number,  minutesNow: number, minutesOriginal: number, timeReal: number) {
  if (timeReal > 0) {
    if (hoursNow < hoursOriginal) {
      return Math.floor(Math.abs(timeReal));
    } else {
      //caso retraso hora nueva mayor
      let hoursDiffH = (hoursOriginal - hoursNow) * 60;
      let minDifference  = minutesOriginal - minutesNow;
      return Math.abs(minDifference + hoursDiffH);
    }
    
  } else if (hoursNow === hoursOriginal) {
    //caso misma hora
    let differenceMinute = minutesNow - minutesOriginal;
    if (differenceMinute < 0) {
      //adelanto 
      return Math.abs(differenceMinute);
    } else if (differenceMinute > 0) {
      return differenceMinute;
    } else {
      return 0;
    }
  } else {
    //caso adelanto
    if (hoursNow > hoursOriginal) {
      //Caso raro TO CHANGE
      return Math.floor(Math.abs(timeReal));
    } else {
      let hoursDiffH = (hoursNow - hoursOriginal) * 60;
      let minDifference  = minutesOriginal -minutesNow;
      return Math.abs(minDifference - hoursDiffH);
    }
    
  }
}

export function useCalculateRealTimeLinesStop() {
  const t = useTranslate();
  const locale = useLanguage();

  

  function calculateTime(timeTheory: String, timeReal: number) {
    /* const splitTimeTheory = timeTheory.split(':');
    const secondsTheory = parseInt(splitTimeTheory[0]) * 60 * 60 + parseInt(splitTimeTheory[1]) * 60 + parseInt(splitTimeTheory[2]);
    const splitTimeReal = timeReal.split(':');
    const secondsReal = parseInt(splitTimeReal[0]) * 60 * 60 + parseInt(splitTimeReal[1]) * 60 + parseInt(splitTimeReal[2]);
    let timeDifference =  secondsTheory - secondsReal;
    if (timeDifference >= 60) {
      //caso adelanto, pasa antes
      return 'Adelantado ' + timeDifference + 's' + ' ' + timeReal
    } else if (timeDifference <= -60) {
      //caso retraso, pasa después
      return 'Retrasado  ' + timeDifference + 's' + ' ' + timeReal
    } else {
      return '';
    } */

    if (timeReal != 0) {
      const splitTimeTheory = timeTheory.split(':');
      let hour = parseInt(splitTimeTheory[0]);
      let minute = parseInt(splitTimeTheory[1]);
      let seconds = parseInt(splitTimeTheory[2]);
      minute = minute + seconds / 60;

      let modMinutes = minute + timeReal;
      let modHour = 0;
      
      if (modMinutes > 60) {
        modHour = Math.floor(modMinutes / 60);
        modMinutes = modMinutes % 60;
        hour = hour + modHour > 23 ? hour + modHour - 24 : hour + modHour;
        minute = modMinutes;
        
      } else if(modMinutes < 0) {
        if (timeReal <= -60) {
          //caso más de una hora de adelanto
          modHour = - Math.floor(Math.abs(timeReal) / 60);
          modMinutes = - (Math.abs(timeReal) % 60);
          let prevValue = minute + modMinutes;
          modMinutes = prevValue < 0 ? 60 + prevValue : prevValue;
          if (prevValue < 0) {
            modHour = modHour - 1;
          }
        } else {
          //caso de solo una hora
          modMinutes = 60 + modMinutes;
          modHour--;
        }
        hour = hour + modHour < 0 ?  hour + modHour + 24 : hour + modHour;
        minute = Math.floor(Math.abs(modMinutes)); 
      } else {
        minute = Math.floor(modMinutes);
      }

      let timeFormatted = `${hour < 10 ? '0' +  hour : hour}:${minute < 10 ? '0' +  Math.floor(minute) : Math.floor(minute)}`;
      let minutesDifferenceReal = calculateDifferenceHours(hour, parseInt(splitTimeTheory[0]), Math.floor(minute), parseInt(splitTimeTheory[1]), timeReal);
      if (minutesDifferenceReal !== 0) {
        if (timeReal > 0) {

          return `${minutesDifferenceReal} ${minutesDifferenceReal < 2 ? t('stop_time_real_late_min') : t('stop_time_real_late')} ${timeFormatted}`;
        } else if (timeReal < 0) {
          return `${minutesDifferenceReal} ${minutesDifferenceReal < 2 ? t('stop_time_real_late_min') : t('stop_time_real_ahead')} ${timeFormatted}`;
        }
      } else {
        return t('stop_time_real_theory');
      }
      
    }

    return t('stop_time_real_in_time');
  }
  return {calculateTime};
}

export function calculateTimeTesting(timeTheory: String, timeReal: number, t: Function) {
  /* const splitTimeTheory = timeTheory.split(':');
  const secondsTheory = parseInt(splitTimeTheory[0]) * 60 * 60 + parseInt(splitTimeTheory[1]) * 60 + parseInt(splitTimeTheory[2]);
  const splitTimeReal = timeReal.split(':');
  const secondsReal = parseInt(splitTimeReal[0]) * 60 * 60 + parseInt(splitTimeReal[1]) * 60 + parseInt(splitTimeReal[2]);
  let timeDifference =  secondsTheory - secondsReal;
  if (timeDifference >= 60) {
    //caso adelanto, pasa antes
    return 'Adelantado ' + timeDifference + 's' + ' ' + timeReal
  } else if (timeDifference <= -60) {
    //caso retraso, pasa después
    return 'Retrasado  ' + timeDifference + 's' + ' ' + timeReal
  } else {
    return '';
  } */

  if (timeReal != 0) {
    const splitTimeTheory = timeTheory.split(':');
    let hour = parseInt(splitTimeTheory[0]);
    let minute = parseInt(splitTimeTheory[1]);
    let seconds = parseInt(splitTimeTheory[2]);
    minute = minute + seconds / 60;

    let modMinutes = minute + timeReal;
    let modHour = 0;
    
    if (modMinutes > 60) {
      modHour = Math.floor(modMinutes / 60);
      modMinutes = modMinutes % 60;
      hour = hour + modHour > 23 ? hour + modHour - 24 : hour + modHour;
      minute = modMinutes;
      
    } else if(modMinutes < 0) {
      if (timeReal <= -60) {
        //caso más de una hora de adelanto
        modHour = - Math.floor(Math.abs(timeReal) / 60);
        modMinutes = - (Math.abs(timeReal) % 60);
        let prevValue = minute + modMinutes;
        modMinutes = prevValue < 0 ? 60 + prevValue : prevValue;
        if (prevValue < 0) {
          modHour = modHour - 1;
        }
      } else {
        //caso de solo una hora
        modMinutes = 60 + modMinutes;
        modHour--;
      }
      hour = hour + modHour < 0 ?  hour + modHour + 24 : hour + modHour;
      minute = Math.floor(Math.abs(modMinutes)); 
    } else {
      minute = Math.floor(modMinutes);
    }

    let timeFormatted = `${hour < 10 ? '0' +  hour : hour}:${minute < 10 ? '0' +  Math.floor(minute) : Math.floor(minute)}`;
    let minutesDifferenceReal = calculateDifferenceHours(hour, parseInt(splitTimeTheory[0]), Math.floor(minute), parseInt(splitTimeTheory[1]), timeReal);
    if (minutesDifferenceReal !== 0) {
      if (timeReal > 0) {
        return `${minutesDifferenceReal} ${t('stop_time_real_late')} ${timeFormatted}`;
      } else if (timeReal < 0) {
        return `${minutesDifferenceReal} ${t('stop_time_real_ahead')} ${timeFormatted}`;
      }
    } else {
      return t('stop_time_real_theory');
    }
    
  }

  return t('stop_time_real_theory');
}