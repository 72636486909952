import {useLanguage, useTranslate} from '../../context/languageContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';

export default function OpenData(props) {
  const theme = useTheme();
  const t = useTranslate();

  const Apple = R.drawables.providers.appleStore;
  const Google = R.drawables.providers.googlePlay;
  const EuskadiLogo = R.drawables.providers.euskadiLogo;

  const styles = {
    web: {
      textDecoration: 'none'
    },
    textBar: {
      textAlign: 'left',
      width: 160,
      paddingInline: 15,
      fontWeight: 600,
      color: theme.colors.lightShort,
      cursor: 'pointer',
      fontSize: '12px',
      transition: 'all 300ms linear'
    },

    downloadText: {
      textAlign: 'center',
      width: 160,
      paddingInline: 40,
      marginTop: 20,
      fontWeight: 600,
      color: theme.colors.white,
      fontSize: '12px',
      transition: 'all 300ms linear'
    },

    separator: {
      backgroundColor: theme.colors.mediumDark,
      height: 2,
      width: '100%'
    },

    img: {
      padding: '5px',
      cursor: 'pointer',
      width: '200px',
      height: '47px'
    },
    logo: {
      padding: '5px',
      marginTop: '10px',
      cursor: 'pointer',
      width: '200px',
      height: '35px',
      backgroundColor: 'white',
      borderRadius: '4px',
      justifyContent: 'center',
      alignSelf: 'center',
    }
  };
  return (
    <div style={props.style}>
      <a style={styles.web} href={useLanguage()==='eu' ? "https://opendata.euskadi.eus/katalogoa/-/moveuskadi-euskadiko-garraio-sare-publikoari-buruzko-ibilbideen-planifikatzailea-ordutegiak-geralekuak-denbora-kostua-eta-datu-gehiago/" : 'https://opendata.euskadi.eus/catalogo/-/geograficos/moveuskadi-red-de-transporte-publico-de-euskadi-horarios-paradas-tiempo-coste-y-otros-datos-para-planificar-rutas/'} target="_blank">
        <h1 style={styles.textBar}>{t('openData')}</h1>
      </a>
      <div style={styles.separator} />
      <h1 style={styles.downloadText}>{t('downloadApp')}</h1>

      <div style={{ display: 'flex' }}>
        <Apple style={styles.img} onClick={()=>{window.open('https://apps.apple.com/us/app/moveuskadi/id1665662284')}}/>
        <Google style={styles.img} onClick={()=>{window.open('https://play.google.com/store/apps/details?id=com.ejie.moveuskadi')}}/>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }} onClick={() => window.open('https://www.euskadi.eus/inicio/', 'myWindow')}>
        <EuskadiLogo style={styles.logo}/>
      </div>
    </div>
  );
}
