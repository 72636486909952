import { useTheme } from '../../context/themeContext';
import { R } from '../../R';

export default function ButtonIcon(props, ref) {
  const theme = useTheme();
  const Icon = props.icon ?? R.drawables.general.Ic_close;
  const styles = {
    button: {
      display: 'flex',
      height: props.size ?? 40,
      width: props.size ?? 40,
      minHeight: props.size ?? 40,
      minWidth: props.size ?? 40,
      background: props.disabled
        ? theme.colors.neutralBackground
        : props.selected
        ? theme.colors.mediumDark
        : props.background ?? theme.colors.white,
      borderRadius: '7px',
      color: props.textColor,
      border: props.borderColor
        ? `0.1px solid ${props.borderColor}`
        : `0.1px solid ${theme.colors.border}`,
      alignItems: 'center',
      fontSize: '10px',
      justifyContent: 'center',
      cursor: 'pointer'
    },
    icon: {
      fill: props.disabled ? theme.colors.neutralMediumGray : props.iconColor ?? theme.colors.white,
      width: props.iconSize ?? 'auto',
      height: props.iconSize ?? 'auto'
    }
  };

  return (
    <button
      data-tip={props.dataTip}
      style={{ ...styles.button, ...props.style }}
      data-for={props.dataFor}
      disabled={props.disabled}
      onClick={props.onClick ?? null}
      onMouseLeave={props.onMouseLeave ?? null}
      onMouseDown={props.onMouseDown ?? null}>
      {Icon?.render ? <Icon style={styles.icon} /> : <img src={Icon} style={styles.icon} />}
      {props.children}
    </button>
  );
}
