import { useTheme } from '../../context/themeContext';

export default function Button(props, ref) {
  const theme = useTheme();
  const Icon = props.icon;
  const styles = {
    button: {
      display: 'flex',
      height: props.size ?? 40,
      opacity: props.disabled ? 0.5 : 1,
      background: props.background ?? theme.colors.white,
      borderRadius: props.borderRadius ?? '10px',
      color: props.textColor,
      border: props.selected
        ? `0.1px solid ${theme.colors.mediumDark}`
        :props.borderColor
          ? `0.1px solid ${props.borderColor}`
          : `0.1px solid ${theme.colors.border}`,
      alignItems: 'center',
      fontSize: props.fontSize ? props.fontSize : '13px',
      whiteSpace: 'nowrap',
      paddingInline: '14px',
      cursor: props.disabled ? 'auto' : 'pointer',
      fontWeight: props.fontWeight
    },
    icon: {
      fill: props.selected ? theme.colors.white : props.iconColor ?? theme.colors.white,
      marginRight: !props.iconRight ? '5px' : '0px',
      marginLeft: props.iconRight ? '5px' : '0px',
      height: props.iconSize ?? 25
    }
  };

  return (
    <div style={props.style} className={props.className}>
      <button
        style={!props.classNameButton ? { ...styles.button, ...props.style } : null}
        disabled={props.disabled}
        className={props.classNameButton}
        onClick={props.onClick ?? null}>
        {Icon?.render ? (
          !props.iconRight ? (
            <Icon style={styles.icon} />
          ) : null
        ) : !props.iconRight ? (
          <img src={Icon} style={styles.icon} />
        ) : null}
        {props.text ? <div style={props.style}>{props.text}</div> : 'text'}
        {Icon?.render ? (
          props.iconRight ? (
            <Icon style={styles.icon} />
          ) : null
        ) : props.iconRight ? (
          <img src={Icon} style={styles.icon} />
        ) : null}
        {props.children}
      </button>
    </div>
  );
}
