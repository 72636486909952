import { configureStore } from '@reduxjs/toolkit';
import { favoriteServiceApi } from './login/favorite/FavoriteServiceApi';
import { lineServiceApi } from './login/line/lineServiceApi';
import { NewsServiceApi } from './login/news/NewsServiceApi';
import { SearchServiceApi } from './login/search/SearchServiceApi';
import { authServiceApi } from './login/services/authServiceApi';
import { MessageServiceApi } from './login/services/MessagesServiceApi';
import { NotificationConfServiceApi } from './login/services/NotificationConfServiceApi';
import { vehiclesServiceApi } from './login/services/vehiclesServiceApi';
import { ShareServiceApi } from './login/share/ShareServiceApi';
import { GbfsServiceApi } from './login/explore/GbfsServiceApi';
import { SubscriptionsServiceApi } from './login/subscriptions/SubscriptionsServiceApi';
import { authSlice } from './slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from './slices/eventsModalstoggleVisibilitySlice';
import { favoritesSlice } from './slices/favoritesSlice';
import { filtersSlice } from './slices/filtersSlice';
import { lineSlice } from './slices/lineSlice';
import { newsSlice } from './slices/newsSlice';
import { notificationSlice } from './slices/notificationSlice';
import { searchSlice } from './slices/searchSlice';
import { shareSlice } from './slices/shareSlice';
import { subscriptionsSlice } from './slices/subscriptionsSlice';
import { vehicleSlice } from './slices/vehicleSlice';
import {markersSlice} from "./slices/markersSlice";
import { agencySlice } from './slices/agencySlice';
import { agencyServiceApi } from './login/services/AgencyService';
import { alertsSlice } from './slices/alertsSlice';
import { stopsServiceApi } from './login/explore/stopsService';

export const store = configureStore({
  reducer: {
    [eventsModalstoggleVisibilitySlice.name]: eventsModalstoggleVisibilitySlice.reducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [GbfsServiceApi.reducerPath]: GbfsServiceApi.reducer,
    [SubscriptionsServiceApi.reducerPath]: SubscriptionsServiceApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [SearchServiceApi.reducerPath]: SearchServiceApi.reducer,
    [searchSlice.name]: searchSlice.reducer,
    [newsSlice.name]: newsSlice.reducer,
    [markersSlice.name]: markersSlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [favoritesSlice.name]: favoritesSlice.reducer,
    [subscriptionsSlice.name]: subscriptionsSlice.reducer,
    [filtersSlice.name]: filtersSlice.reducer,
    [favoriteServiceApi.reducerPath]: favoriteServiceApi.reducer,
    [MessageServiceApi.reducerPath]: MessageServiceApi.reducer,
    [NewsServiceApi.reducerPath]: NewsServiceApi.reducer,
    [NotificationConfServiceApi.reducerPath]: NotificationConfServiceApi.reducer,
    [vehiclesServiceApi.reducerPath]: vehiclesServiceApi.reducer,
    [lineServiceApi.reducerPath]: lineServiceApi.reducer,
    [vehicleSlice.name]: vehicleSlice.reducer,
    [lineSlice.name]: lineSlice.reducer,
    [ShareServiceApi.reducerPath]: ShareServiceApi.reducer,
    [shareSlice.name]: shareSlice.reducer,
    [agencySlice.name]: agencySlice.reducer,
    [alertsSlice.name]: alertsSlice.reducer,
    [agencyServiceApi.reducerPath]: agencyServiceApi.reducer,
    [stopsServiceApi.reducerPath]: stopsServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware()
      .concat(authServiceApi.middleware)
      .concat(NotificationConfServiceApi.middleware)
      .concat(MessageServiceApi.middleware)
      .concat(vehiclesServiceApi.middleware)
      .concat(lineServiceApi.middleware)
      .concat(ShareServiceApi.middleware)
      .concat(agencyServiceApi.middleware)
      .concat(NewsServiceApi.middleware)
      .concat(stopsServiceApi.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
