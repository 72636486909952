import L from 'leaflet';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Apis } from '../../../../../../domain/Apis';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import Ic_BusUrbano from '../../../../../../res/values/drawables/transport/Ic_BusUrbano.svg';
import { VM } from '../../../../../../viewmodel/VM';
import Alert from '../../../../../components/commons/Alert';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import LineIcon from './LineIcon';
import NextStops from './NextStops';
import StopCollapse from './StopCollapse';
import StopMidInfo from './StopMidInfo';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import SelectorDateStop from './SelectorDateStop';
import { useSelector } from 'react-redux';
import { lineFilteredState, lineSlice } from '../../../../../../redux/slices/lineSlice';
import { useGetStopRealLineTimesMutation } from '../../../../../../redux/login/explore/stopsService';
import { agencyInformation } from '../../../../../../redux/slices/agencySlice';
import LinesNearMarker from '../linesNear/LinesNearMarker';
import StopsLinesBar from '../stopsLinesNear/StopsLinesBar';

export default function StopDetail(props) {
  const theme = useTheme();
  const CloseIcon = R.drawables.general.Ic_close;
  const responsive = useResponsive();
  const language = useLanguage();
  const dispatch = useDispatch();
  const iconVehicles = new L.icon({
    iconUrl: Ic_BusUrbano,
    iconRetinaUrl: Ic_BusUrbano,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(15, 15),
    className: 'vehicle-position-icon'
  });

  const [type, setType] = useState();
  const [iconType, setIconType] = useState();
  const [lines, setLines] = useState();
  const [linestimes, setLinesTimes] = useState(null);
  const [stopData, setStopData] = useState(props.stop);
  const [realTimes, setRealTimes] = useState([]);
  const [alertsList, setAlertsList] = useState();
  const filteredLine = useSelector(lineFilteredState);
  const setFilteredLine = (value) => dispatch(lineSlice.actions.updateFilteredLine(value));
  const [exitDate, setExitDate] = useState();
  const [GetStopRealTimes] = useGetStopRealLineTimesMutation();
  const dataOrigin = useSelector(agencyInformation).dataOrigin;
  const t = useTranslate();

  useEffect(() => {
    async function getAlerts() {
      let allAlerts = await VM.alerts.get(language);
      let selectedStopAlerts = [];
      for (let item in allAlerts) {
        for (let stop in allAlerts[item].paradas) {
          if (allAlerts[item].paradas[stop].idParada == props.stop.id) {
            selectedStopAlerts.push(allAlerts[item]);
          }
        }
      }
      setAlertsList(selectedStopAlerts);
    }
    getAlerts();
  }, [props.stop, language]);

  useEffect(() => {
    setLines(null);
    setType(null);
    setIconType(null);
    async function fetchData() {
      if (!VM.dataOrigin.data.length) {
        let res = dataOrigin;
        let foundData = res.find((element) => element.id === props.stop.agencyOriginId);
        if (!foundData) {
        } else {
          if (foundData?.groupTxt) {
            setType(foundData?.groupTxt);
            setIconType(await VM.icons.getIcon(foundData?.operatorIconid));
          } else {
            setType(await Apis.agency?.getAgency(language, foundData?.agencyId));
            setIconType(await VM.icons.getIcon(foundData?.agencyId, 'agency'));
          }
        }
      } else {
        setIconType(
          await VM.icons.getIcon(VM.dataOrigin?.data[props.stop.agencyOriginId]?.agencyId, 'agency')
        );
        setType(
          await Apis.agency?.getAgency(language, dataOrigin[props.stop.agencyOriginId]?.agencyId)
        );
      }
      setLines(await Apis.stops.getStopLines(language, props.stop.id));
      setStopData(await Apis.stops.getStop(language, props.stop.id));
      //setLinesTimes(await Apis.stops.getStopLinestimes(language, props.stop.id));
    }
    fetchData();
    let copy = `${GeneralUtils.getBaseUrl()}/#/stop?${GeneralUtils.convertToHex(
      `id=${props.stop.id}`
    )}`;
    window.history.pushState('a', 'a', copy);
    //setFilteredLine(undefined);
    setExitDate(undefined);
  }, [props.stop, language, dataOrigin]);

  useEffect(() => {
    async function obtainTimes(date) {
      const resultTime = await Apis.stops.getStopLinestimes(language, props.stop.id, date);
      !resultTime?.error && setLinesTimes(JSON.parse(JSON.stringify(resultTime)));
      //TO CHANGE
      if (!date && !resultTime?.error) {
        const resultReal = await GetStopRealTimes({ locale: language, id: props.stop.id });
        if (resultReal?.data) {
          setRealTimes(resultReal?.data);
        } else {
          setRealTimes([]);
        }
      }
    }
    setLinesTimes(null);
    if (exitDate) {
      let formattedDate = new Date(exitDate);
      obtainTimes(
        `${formattedDate.getFullYear()}/${formattedDate.getMonth() + 1}/${formattedDate.getDate()}`
      );
    } else {
      obtainTimes();
    }
  }, [props.stop, language, exitDate]);

  const styles = {
    content: {
      position: 'absolute',
      display: 'flex',
      padding: 12,
      marginInline: !responsive.isBig ? '25px' : '105px',
      marginTop: '10px',
      width: '80vw',
      maxWidth: '345px',
      minWidth: '232px',
      maxHeight: window.innerHeight - 150,
      flexDirection: 'column',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '12px'
    },
    subContent: {
      paddingInline: '12px',
      textAlign: 'left',
      alignContent: 'center'
    },
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center',
      flexWrap: 'wrap'
    },
    icon: {
      marginRight: 10,
      padding: 5,
      width: 15,
      height: 15,
      borderRadius: 5,
      background: theme.colors.border
    },
    secondaryText: {
      color: theme.colors.textSecondary,
      fontSize: 13,
      fontWeight: 500
    },
    mainText: {
      color: theme.colors.textPrimary,
      fontSize: 15,
      fontWeight: 600,
      marginTop: 5
    }
  };

  const renderLines = () => {
    let res = [];
    if (!lines?.error)
      for (let line in lines) {
        let tripId;
        for (let linestime in linestimes) {
          if (linestimes[linestime].lineId === lines[line].id) {
            tripId = linestimes[linestime].tripId;
            break;
          }
        }
        //let codeLine = props.text === 'REG.EXP' ? '' : props.text;
        let dataOri = dataOrigin.find((element) => element.id === lines[line].agencyOriginId);
        if (
          dataOri &&
          !(
            (
              dataOri?.label && dataOri?.label?.toLowerCase()?.includes('renfe media distancia')
            ) /*  || dataOri?.label === 'Origen GTFS de RENFE Cercanias' */
          )
        ) {
          res.push(
            <LineIcon
              key={line}
              style={{ marginRight: 10, padding: 5, marginTop: 5 }}
              text={VM.lines.data[lines[line].id]?.code}
              textColor={'#' + VM.lines.data[lines[line].id]?.routeTextColor}
              onClick={() => {
                //PASS HEADSIGN or tripId
                let selectedLine = lines[line].id;
                VM.lines.setSelected(lines[line].id, tripId, lines[line].code);

                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportModalWidget({
                    element: selectedLine
                  })
                );
              }}
              color={lines[line].routeColor ? '#' + lines[line].routeColor : theme.colors.main}
            />
          );
        }
      }
    if (!lines)
      res.push(
        <div key={'loader'} style={{ marginTop: 11, marginBottom: 16 }}>
          <ClipLoader size={20} />
        </div>
      );
    return res;
  };

  const renderLinesFilters = () => {
    let res = [];
    if (!lines?.error) {
      for (let line in lines) {
        //let codeLine = props.text === 'REG.EXP' ? '' : props.text;
        let dataOri = dataOrigin.find((element) => element.id === lines[line].agencyOriginId);
        if (
          dataOri &&
          !(
            (
              dataOri?.label && dataOri?.label?.toLowerCase()?.includes('renfe media distancia')
            ) /*  || dataOri?.label === 'Origen GTFS de RENFE Cercanias' */
          )
        ) {
          let filteredStyle = filteredLine
            ? { opacity: lines[line].id === filteredLine ? 1 : 0.2 }
            : {};
          res.push(
            <LineIcon
              key={line}
              style={{ ...{ marginRight: 10, padding: 5, marginTop: 5 }, ...filteredStyle }}
              text={VM.lines.data[lines[line].id]?.code}
              textColor={'#' + VM.lines.data[lines[line].id]?.routeTextColor}
              onClick={() => {
                if (lines[line].id === filteredLine) {
                  setFilteredLine(undefined);
                } else {
                  setFilteredLine(lines[line].id);
                }
              }}
              color={lines[line].routeColor ? '#' + lines[line].routeColor : theme.colors.main}
            />
          );
        }
      }
    }
    return res;
  };

  const renderAlerts = () => {
    let res = [];
    let alerts = props.stop.alert !== null ? props.stop.alert : alertsList;
    // let alerts = props.stop.alert;

    for (var alert in alerts) {
      res.push(
        <Alert
          key={alerts[alert].idAlerta}
          style={{ margin: 5 }}
          title={alerts[alert]?.titulo}
          description={alerts[alert]?.descripcion}
        />
      );
    }
    return res;
  };

  return (
    <div style={styles.content}>
      <CloseIcon
        style={{ minHeight: 25, cursor: 'pointer', marginLeft: 'auto' }}
        fill={theme.colors.black}
        onClick={() => props.onClose()}
      />

      <div style={styles.subContent}>
        <div style={styles.row}>
          <img style={{ ...styles.icon }} src={iconType} />
          <div style={styles.secondaryText}>{type?.longName ?? type}</div>
        </div>
        <div style={styles.mainText}>
          {stopData?.stopMunicipality
            ? `${stopData?.name} (${stopData?.stopMunicipality})`
            : props.stop.name}
        </div>
        <div style={styles.row}>{renderLines()}</div>
        <div style={{ ...styles.secondaryText, marginTop: 15 }}>{props.stop.description}</div>
      </div>

      {renderAlerts()}

      <StopMidInfo
        stop={
          stopData?.stopMunicipality
            ? { ...props.stop, ...{ name: `${stopData?.name} (${stopData?.stopMunicipality})` } }
            : props.stop
        }
      />
      {props.stop.phone && props.stop.web ? (
        <StopCollapse style={{ marginTop: 10 }} stop={props.stop} />
      ) : null}
      <div style={{ flex: 1, overflow: 'scroll' }}>
        {/* <LinesNearMarker title={t('lines_near_stop')} optionalLines={lines} latitude={props.stop?.latitude} longitude={props.stop?.longitude} /> */}
        <StopsLinesBar
          latitude={props.stop?.latitude}
          longitude={props.stop?.longitude}
          banStop={props.stop}
          setMarkerSelected={props.setMarkerSelected}
        />
        <SelectorDateStop exitDate={exitDate} setExitDate={setExitDate} />
        <div style={styles.row}>{renderLinesFilters()}</div>
        {lines?.length ? (
          <NextStops
            currentDate={exitDate}
            stop={props.stop}
            realTimes={realTimes}
            linestimes={
              filteredLine
                ? linestimes?.filter((lineTime) => lineTime?.lineId === filteredLine)
                : linestimes
            }
          />
        ) : null}
      </div>
    </div>
  );
}
