export const strings = {
  filters: 'Filtres',
  appName: 'Moveuskadi',
  button_delete: 'Effacer',
  undo: 'Annuler',
  remove: 'Effacer',
  explore: 'Explorer',
  saves: 'Favoris',
  openData: 'Open Data',
  downloadApp: "Téléchargez l'application mobile de moveuskadi",
  circulation: 'Circulation',
  account: 'Mon Compte',
  login: 'Démarrer session',
  login2: 'Démarrez une session',
  incidence: 'Notifications',
  transbord: 'Changement',
  links:'Liens',
  stopSaved: 'Vous avez enregistré cet arrêt,',
  saveStopAlert: 'Souhaitez-vous activer une alerte ?',
  saveAlert: 'Souhaitez-vous activer une alerte ?',
  activateAlerts: 'Activer alertes',
  noThanks: 'Non merci',
  saveStopAlertDescription:
    "Créez une alerte pour nous permettre de vous avertir si l'arrêt est modifié. Quand souhaitez vous la recevoir ?",
  saveStopAlertBody:
    "Sélectionnez lorsque vous souhaitez recevoir des alertes en cas d'incident sur les arrêts enregistrés",
  saveLineAlertDescription:
    "Créez une alerte pour nous permettre de vous avertir en cas d'incident sur la ligne.",
  loginToFav: 'Démarrer session pour enregistrer dans favoris',
  loginToReport: 'Démarrez une session pour signaler une erreur',
  loginToFavs: 'Démarrer session pour enregistrer dans favoris',
  loginToAlerts: 'Démarrez une session pour configurer une alerte',
  loginToFavBody:
    "Démarrez une session ou créez un compte pour accéder à toutes les fonctionnalités de l'application.",
  deleteFavConfirm: 'Souhaitez-vous supprimer ce favori ?',
  register: "M'enregistrer",
  myAccount: 'Mon Compte',
  infoPersonal: 'Informations personnelles',
  whereDoYouGo: 'Où souhaitez-vous aller ?',
  simpleTicket: 'Billet simple',
  activeAlert: 'Activer alertes',
  removeAlert: 'Supprimer alerte',
  alertAdded: 'Alerte ajoutée',
  alertRemoved: 'Alerte supprimée',
  password: 'Mot de passe',
  passwordConditions:
    'Pour modifier le mot de passe, vous devez saisir minimum 6 chiffres, une majuscule, une minuscule et un chiffre',
  man: 'Homme',
  woman: 'Femme',
  today: "Aujourd'hui",
  exitTo: 'Départs pour',
  another: 'Autre',
  setAlias: 'Créez un alias',
  alias: 'Alias',
  aliasColon: 'Alias:',
  save: 'Enregistrer',
  share: 'Partager',
  shareVia: 'Partager avec',
  linkToShare: 'Lien à partager',
  copyLink: 'Copier lien',
  copiedToClipboard: 'Copié dans le presse-papier',
  downloadMobileApp: "Téléchargez l'application mobile",
  goToAppleStore: 'Aller à Apple Store',
  goToAccount: 'Aller à mon compte',
  goToGooglePlay: 'Aller à Google Play Store',
  noBinary: 'Non-binaire',
  ageUnder19: 'entre 0 et 19',
  age20to45: 'entre 20 et 45',
  age45to65: 'entre 45 et 65',
  ageOver65: '>65',
  copyUrl: 'Url copiée',
  changePass: 'Changer mot de passe',
  change: 'Changer',
  manageAccount: 'Gérer compte',
  language: 'Langue',
  help: 'Aide',
  support: 'Support et aide',
  manageNotifications: 'Gestion des notifications',
  news: 'Actualités',
  guided: 'Guidé',
  guidedNotificationsNotice:
    "Si vous activez cette option, Moveuskadi vous enverra des notifications concernant les prochaines étapes et les changements au cours du processus d'orientation.",
  trafficAlerts: "Alertes et avis d'actualité",
  general: 'Général',
  privacy: 'Politique de confidentialité',
  useConditions: "Conditions d'utilisation",
  terms: 'Conditions du service',
  shareApp: 'Partager appli',
  reviewApp: 'Évaluer',
  dataAccount: 'Données du compte',
  minNum: 'Au moins un chiffre',
  charMaxAndMin: 'Minimum 6 caractères et maximum 30 caractères',
  and: 'et',
  accept: "J'accepte",
  minMayusMin: 'Au moins une lettre majuscule et minuscule',
  noMatch: 'Ne coïncident pas',
  fillFields: 'Complétez les champs suivants',
  personalInfo: 'Données personnelles',
  createAccount: 'Créer compte',
  noStationsSaved:
    "Vous n'avez enregistré aucune gare ou arrêt dans vos favoris. Choisissez ceux et celles que vous utilisez le plus et enregistrez-les pour y accéder rapidement.",
  meetABit: 'Pour nous connaître un peu plus',
  noTransportSaved: "Vous n'avez aucun transport favori",
  noTransportSavedBody:
    'Pour configurer vos transports favoris, vous devez les enregistrer sur votre compte, dans la section des transports favoris.',
  nameComa: 'Nom: ',
  name: 'Nom',
  email: 'Courrier électronique',
  emailLong: 'Courrier électronique',
  userName: "Prénom de l'utilisateur-trice",
  needANewPass: "Avez-vous besoin d'un nouveau mot de passe ?",
  introduceYourEmail:
    'Saisissez le courrier électronique que vous avez utilisé pour créer votre compte',
  gender: 'Genre',
  ageComa: 'Âge :',
  age: 'Âge',
  phone: 'Téléphone',
  website: 'Site web',
  direction: 'Adresse',
  seeAll: 'Voir toutes',
  selectAll: 'Sélectionner tous',
  selectAllFavorites: 'Sélectionner les favoris',
  subscribeNews: 'Souscrire nouvelles',
  selectOpNews:
    "Sélectionnez les opérateurs desquels vous souhaitez recevoir des notifications en cas d'une nouvelle actualité.",
  lessInfo: 'Afficher moins',
  moreInfo: 'Afficher plus',
  goThere: 'Arriver là-bas',
  notFound: "Rien n'a été trouvé",
  messageState: 'État de mes commentaires',
  all: 'Tous',
  inProcess: 'En processus',
  answered: 'Répondus',
  checkSearch:
    'Vérifiez que la recherche est correctement rédigée. Essayez en ajoutant une ville, un état ou un code postal.',
  myDirections: 'Mes adresses',
  directions: 'Adresses',
  searchByCategory: 'Recherche par catégorie',
  recents: 'Récents',
  useMyLocation: 'Ma localisation',
  es: 'Espagnol',
  eu: 'Basque',
  en: 'Anglais',
  fr: 'Français',
  visualizer_filters: 'Filtres',
  visualizer_filtersAll: 'Tous',
  visualizer_filtersBoth: 'Les deux',
  visualizer_moreFilters: 'Plus de filtres',
  visualizer_message_moreFilters:
    "Sélectionnez les opérateurs que vous utilisez le plus. Seuls ces derniers s'afficheront sur la carte.",
  visualizer_filters_empty: "Aucun filtre n'a pu être chargé. Vérifiez votre connexion",
  visualizer_filters_motorized: 'Motorisation',
  visualizer_filters_onlySelected: 'Seulement un',
  visualizer_filters_other: 'Autres',
  visualizer_filters_selected: 'sélectionnés',
  visualizer_filter_selected: 'sélectionnés',
  visualizer_poi: "Points d'intérêt",
  visualizer_ModosdeTransporte: 'Transports',
  visualizer_operators: 'Opérateurs',
  systemNotifications: 'Notifications du système',
  systemNotificationsNews:
    'Si vous activez cette option, Moveuskadi vous enverra des notifications sur les actualités auxquelles vous vous êtes souscrit-e.',
  systemNotificationsAlerts:
    'Ces notifications vous avertissent lorsqu’un incident s’est produit sur une ligne, un arrêt et informations que vous avez souscrit-e',
  systemNotificationsNotice:
    'Si vous activez cette option, Moveuskadi vous enverra des notifications sur les changements importants et les erreurs dans le système. Nous vous recommandons de les garder actives.',
  h: 'h',
  min: 'min',
  accesible: 'Accessible',
  exitNow: 'Partir maintenant',
  exitAt: 'Départ à',
  exitAtText: 'Départ',
  accountNoActivated: 'Compte non activé',
  accountCreated: 'Compte créé. Pour activer votre compte, veuillez vérifier votre boîte mail.',
  noInternetConnection: "Il n'y a pas de connexion Internet",
  serviceError: 'Défaillance du service',
  reviewLoginOrPass: 'Vérifiez votre courrier électronique ou votre mot de passe',
  seeRoutes: 'Voir itinéraires',
  stopTime: 'À quelle heure allez-vous partir',
  accountActivated: 'Compte activé',
  accountActivatedDes: 'Votre compte a été créé et activé avec succès !',
  accountNoActivatedDes:
    "Un problème est survenu lors de la création et de l'activation de votre compte. Veuillez réessayer plus tard.",
  arriveAt: 'Arrivée',
  preferences: 'Préférences',
  other: 'Autre',
  confirm: 'Confirmer',
  leaveSuggestion: 'Faites une suggestion',
  suggestionNotice:
    "Nous nous efforçons constamment d'améliorer votre expérience. C'est pourquoi nous aimerions savoir quelles sont les fonctionnalités que vous préférez et ce que nous pourrions améliorer.",
  commentStateNotice:
    "Vous pourrez voir l'état de toutes les suggestions et de tous les signalements que vous avez effectués",
  report: 'Signaler',
  timeSoonThanBefore: "Heure antérieure à l'arrêt précédent",
  timeSoonThanBeforeBody:
    "L'heure sélectionnée est antérieure à un temps d'un arrêt précédent. Veuillez sélectionner une heure postérieure",
  reportProblem: 'Signaler un incident',
  reportSystemError: 'Signaler une erreur du système',
  reportOperator: 'Signaler un opérateur',
  reportAnError: 'Signaler une erreur',
  reportStop: 'Signaler un arrêt',
  reportStopNotice: "Choisissez le motif pour lequel vous souhaitez signaler l'arrêt suivant.",
  reportWrongAddress: 'Adresse incorrecte',
  reportStopData: 'Données arrêt incorrectes',
  reportStopState: "État de l'arrêt",
  reportLine: 'Signaler ligne',
  reportLineNotice: 'Choisissez le motif pour lequel vous souhaitez signaler la ligne suivante.',
  reportDataError: 'Données incorrectes',
  reportTimeError: 'Erreur temps de passage',
  reportPlanning: 'Signaler planification',
  reportPlanningNotice:
    'Choisissez le motif pour lequel vous souhaitez signaler la planification suivante.',
  reportDoesntComplyWithPreferences: 'Ne correspond pas aux préférences',
  reportNoResults: 'Aucun résultats',
  reportAlternativeRoutes: "Il existe d'autres itinéraires",
  reportRoute: 'Signaler itinéraire',
  reportRouteNotice: 'Choisissez le motif pour lequel vous souhaitez signaler la planification',
  reportIndicationsError: 'Erreur dans les indications',
  sharePlanning: "Consultez les informations de l'itinéraire suivant",
  leaveComment: 'Écrivez un commentaire',
  sendComment: 'Envoyer commentaire',
  send: 'Envoyer',
  destination: 'Destination',
  origin: 'Origine',
  tomorrow: 'Demain',
  nextStops: 'Prochains départs',
  searchADirection: 'Cherchez une adresse',
  suggestion: 'Suggestion',
  planificationReport: 'Signalement planification',
  stopReport: "Signalement sur l'arrêt",
  lineReport: 'Signalement sur la ligne',
  answer: 'Réponse',
  filterNoResult:
    'Sélectionnez un autre filtre ou recalculez la planification avec une nouvelle configuration.',
  firstExit: 'Premier départ',
  lastExit: 'Dernier départ',
  component_loading: 'Chargement en cours',
  none: 'Aucune',
  favs: 'Favoris',
  allAlerts: 'Toutes les notifications',
  lines: 'Lignes',
  transit_lines: 'Lignes',
  transit_stops: 'Arrêts',
  start: 'Début :',
  end: 'Fin :',
  cantAddStops: 'Aucun arrêt supplémentaire ne peut être ajouté',
  maximumNumberStops: "Vous avez atteint le nombre maximum d'arrêts.",
  stationsQuant: ' arrêts',
  yourLocation: 'Votre localisation',
  accessible_routes: 'Itinéraires accessibles',
  fav_transports: 'Transports favoris',
  eco_routes: 'Itinéraire le plus économique',
  fast_routes: 'Itinéraire le plus rapide',
  transport_modes: 'Moyens de transport',
  monitor_types: 'Type de motorisation',
  operators: 'Opérateurs',
  route_types: "Types d'itinéraires",
  fastest_route: 'ITINÉRAIRE LE PLUS RAPIDE',
  eco_route: 'ITINÉRAIRE LE PLUS ÉCONOMIQUE',
  walk_routes: 'Itinéraire marchent moins',
  walk_route: 'ITINÉRAIRE MARCHER MOINS',
  routes: 'Itinéraires',
  places: 'Lieux',
  ubis: 'Localisations',
  favorites: 'Favoris',
  stations: 'Gares',
  stops: 'Arrêts',
  stops_route: 'Arrêts',
  stop: 'Arrêt',
  stopSaved2: 'Arrêt enregistré',
  stopRemoved: 'Arrêt supprimé',
  directionSaved: 'Adresse enregistré',
  directionRemoved: 'Adresse supprimé',
  poiSaved: "Point d'intérêt enregistré",
  poiRemoved: "Point d'intérêt supprimé",
  planSaved: 'Planification enregistré',
  planRemoved: 'Planification supprimé',
  stopsAndStations: 'Arrêts et gares',
  cancel: 'Annuler',
  next: 'Suivant',
  myTransport: 'Mon transport',
  descriptionMyTransport:
    "Sélectionnez les transports que vous utilisez le plus pour disposer d'un accès rapide depuis la carte.",
  forgotPass: "J'ai oublié mon mot de passe",
  configAlert: 'Configurer alerte',
  pois: "Points d'intérêt",
  logout: 'Fermer session',
  close: 'Fermer',
  alertDetail: 'Détail notification',
  youAreBack: 'Enfin de retour !',
  alreadyHaveAccount: "J'ai déjà un compte",
  errorLogin: 'Erreur en démarrant la session',
  notAccount: "Je n'ai pas de compte.",
  myFavTransp: 'Mes transports favoris',
  date_day: 'un jour',
  date_days: 'jours',
  date_difference: 'Il y a',
  date_weeks: 'semaines',
  date_hours: 'heures',
  date_minute: 'min',
  minute: 'minute',
  minutes: 'minutes',
  date_month: 'un mois',
  date_week: 'une semaine',
  zero_min: '0 min',
  newsDetails: 'Détail actualité',
  moreInf: "Plus d'informations",
  seeMore: 'Voir plus',
  visit: 'Visiter',
  noNews: "Aucune actualité n'a été trouvée",
  edit: 'Éditer',
  otherPois: "Autres points d'intérêt",
  delete: 'Supprimer',
  newPass: 'Nouveau mot de passe',
  oldPass: 'Ancien mot de passe',
  passNew: 'Nouveau mot de passe',
  repeatPassNew: 'Répéter nouveau mot de passe',
  home: 'Maison',
  work: 'Travail',
  add: 'Ajouter',
  addHome: 'Ajouter maison',
  addWork: 'Ajouter travail',
  addDirection: 'Ajouter adresse',
  update: 'Mettre à jour',
  setDirection: "Établissez l'adresse",
  createAlert: 'Créer alerte',
  trainStation: 'Gare ferroviaire',
  monday: 'lundi',
  tuesday: 'mardi',
  wednesday: 'mercredi',
  thursday: 'jeudi',
  friday: 'vendredi',
  saturday: 'samedi',
  sunday: 'dimanche',
  january: 'Janvier',
  february: 'Février',
  march: 'Mars',
  april: 'Avril',
  may: 'Mai',
  june: 'Juin',
  july: 'Juillet',
  august: 'Août',
  september: 'Septembre',
  octuber: 'Octobre',
  november: 'Novembre',
  december: 'Décembre',
  from: 'De',
  time_until2: "jusqu'à",
  until: "jusqu'à",
  time_until: "Jusqu'à",
  untilThe: "Jusqu'à",
  at: 'à',
  success: 'Success',
  recoveryEmailSent:
    "S'il existe un utilisateur correspondant à l'adresse saisie, nous lui enverrons un courrier pour récupérer le mot de passe",
  resetData: 'Données du compte ont été réinitialisés',
  accountDataSaved: 'Données de compte mises à jour',
  accountNotRegistered: "Votre email n'est pas enregistré",
  userExists: 'Il y a déjà un utilisateur avec cet email',
  activateYourAccount: 'Activez votre compte pour pouvoir démarrer une session',
  loginSuccess: 'Vous êtes connecté avec succès',
  logoutSuccess: 'Vous avez réussi à vous déconnecter',
  intermediateStop: 'Arrêt intermédiaire ',
  noLineData: "Il n'existe pas d'informations sur cette ligne.",
  stationOf: 'Estación de ',
  stopOf: 'Arrêt de ',
  removeAccount: 'Supprimer le compte',
  removeAccountNotice:
    "Êtes-vous sûr de vouloir supprimer votre compte Moveuskadi ? Si vous confirmez l'annulation, vous perdrez toutes les informations stockées dans le système (favoris, alertes, messages, etc.) et il ne sera pas possible de les récupérer.",
  walk: 'À pied',
  bus: 'Bus',
  subway: 'Métro',
  funicular: 'Téléphérique',
  tranvia: 'Tramway',
  rentalBike: 'Vélo de location',
  bike: 'Vélo',
  train: 'Train',
  bikes: 'Vélos',
  capacity: 'Capacité',
  markersGroup: 'Groupe de marqueurs',
  leaveTitle:'Mettre un sujet',
  reportIncidence: 'Signaler un bug',
  stopIncidence : "Incidence à l'arrêt",
  lineIncidence: "Incidence en ligne",
  planIncidence: "Incidence sur la planification",
  operatorIncidence: "Incidence sur l'opérateur",
  systemIncidence: "Incident système/applicatif",
  bikeStation: 'Station de vélo',
  poiContainer: "Groupe de points d'intérêt",
  stopContainer: "Groupe d'arrêts",
  container:'Groupe de marqueurs',
  moveInformative:"Le contenu de Moveuskadi est exclusivement informatif. Le fournisseur des données est l'opérateur de transport correspondant. Le Gouvernement Basque ne sera pas responsable des conséquences, dommages ou préjudices pouvant résulter de l'utilisation desdites informations.",
  visualizer_filter_markers_pois: "Cacher les points d'intérêt",
  stopSchedule: "Arrêter l'horaire",
  searchEmptyResults: "Aucun résultat trouvé",
  searchMoreResults: 'Charger plus',
  maxWalkDistance_title: 'Temps de marche maximum',
  maxWalkDistance_placeholder: 'Temps en minutes',
  menu_lines_search: 'Recherche en ligne',
  menu_line_filter_empty: 'Aucune ligne trouvée.',
  menu_line_selector_empty: "Aucun opérateur n'a été sélectionné.",
  select_operator_placeholder: 'Sélectionner un opérateur',
  menu_lines: 'Liste des lignes',
  transit_news: 'Nouvelles et annonces',
  incidence_line: 'Avis en ligne',
  incidence_stop: 'Arrêt des avis',
  filters_newsTransit_operators: 'Opérateurs de filtrage',
  filters_newsTransit_operators_desc: 'Sélectionnez les opérateurs pour lesquels vous souhaitez recevoir des nouvelles et des alertes.',
  stop_time_real_late: 'minutes de retard',
  stop_time_real_ahead: "minutes à l'avance",
  stop_time_real_late_min: 'minute de retard',
  stop_time_real_ahead_min: "minute à l'avance",
  stop_time_real_theory: "Prévue",
  favorites_empty_plansMessage:
    'Vous n’avez enregistré aucun itinéraire. Ajoutez les trajets que vous faites fréquemment et enregistrez-les pour pouvoir y accéder rapidement.',
  favorites_empty_poisMessage:
    'Vous n’avez enregistré aucun point d’intérêt. Ajoutez les points d’intérêt qui vous intéressent ou auxquels vous souhaitez accéder rapidement.',
  goToFav: 'Aller aux favoris',
  subscriptions_empty_newsMessage:
    'Vous n’avez aucune agence de presse avec alertes activées. Activez les alertes de celles qui vous intéressent le plus.',
  subscriptions_empty_linesMessage:
    'Vous n’avez aucune ligne de transport avec alertes activées. Activez les alertes de celles qui vous intéressent le plus.',
  subscriptions_empty_stopsMessage:
    'Vous n’avez aucun arrêt avec alertes activées. Activez les alertes de ceux qui vous intéressent le plus.',
  affected_lines: 'Lignes affectées',
  affected_stops: 'Arrêts affectés',
  fares: 'Tarifs',
  lines_near_stop: 'Lignes à proximité de cet arrêt',
  lines_near_marker: 'Lignes à proximité',
  stop_time_real_in_time: 'À temps',
  visualizer_closestStopsLines: 'Arrêts et lignes à proximité',
};
