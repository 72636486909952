import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {eventsModalstoggleVisibilitySlice} from "../../slices/eventsModalstoggleVisibilitySlice";
import {StatusCodesServer} from "../../hooks";
import {RootState} from "../../store";
import GeneralUtils from "../../../utils/GeneralUtils";

export const NewsServiceApi = createApi({
    reducerPath: 'NewsServiceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: GeneralUtils.getEnvironment(window.location.origin),
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authSlice.accountInformation.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        getAllNewsByPagination: builder.mutation<any, any>({
            query: ({locale, transportmode, agency, platform, pagenumber, elementsperpage}) => ({
                url: `/notice/?locale=${locale}${transportmode ? '&transportmode='+transportmode : ""}${agency ? '&agency='+agency : ""}${platform ?  '&platform='+platform : ""}&pagenumber=${pagenumber ?? ""}&elementsperpage=${elementsperpage ?? ""}`,
                method: 'GET',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),

            async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
                try {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
                    );
                    await queryFulfilled;
                    StatusCodesServer(dispatch, 200);
                } catch (err: any) {
                    StatusCodesServer(dispatch, err.error.status);
                } finally {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
                    );
                }
            }
        }),
        getArticle: builder.mutation<any, any>({
            query: ({locale, id}) => ({
                url: `/notice/${id}?locale=${locale}`,
                method: 'GET',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),

            async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
                try {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
                    );
                    await queryFulfilled;
                    StatusCodesServer(dispatch, 200);
                } catch (err: any) {
                    StatusCodesServer(dispatch, err.error.status);
                } finally {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
                    );
                }
            }
        }),
        getTransportsWithNews: builder.mutation<any, any>({
            query: ({locale}) => ({
                url: `/transportmodeAgencyWithNews?locale=${locale}`,
                method: 'GET',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),

            async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
                try {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
                    );
                    await queryFulfilled;
                    StatusCodesServer(dispatch, 200);
                } catch (err: any) {
                    StatusCodesServer(dispatch, err.error.status);
                } finally {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
                    );
                }
            }
        }),
        updateDateNews: builder.mutation<any, any>({
            query: () => ({
                url: '/notice/lastAccessTime',
                method: 'PUT',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),
        }),
        getDateNews: builder.query<any, any>({
            query: () => ({
                url: '/notice/newNews',
                method: 'GET',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),
        }),
    }),
});

export const {
    useGetAllNewsByPaginationMutation,
    useGetArticleMutation,
    useGetTransportsWithNewsMutation,
    useGetDateNewsQuery,
    useLazyGetDateNewsQuery,
    useUpdateDateNewsMutation,
} = NewsServiceApi;
