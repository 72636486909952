import React, {useEffect, useState} from "react";
import TransitAlert from "./TransitAlert";
import {VM} from "../../../viewmodel/VM";
import {useTranslate} from "../../context/languageContext";
import {useSelector} from "react-redux";
import {accountInformation} from "../../../redux/slices/authSlice";
import {favoritesInformation, favoritesSlice} from "../../../redux/slices/favoritesSlice";
import { linesState } from "../../../redux/slices/lineSlice";
import { ILine } from "../../../types/LineInterfaces";
import { ITransitAlertParsed, StopInfo } from "../../../types/ExploreIntefaces";
import { agencyInformation } from "../../../redux/slices/agencySlice";

type Props = {
    data: any;
    selected: number
    setAlert: any
}

export default function TransitBody(props: Props) {

    const [renderFavs, setRenderFavs] = useState(<div/>)
    const [renderAlerts, setRenderAlerts] = useState(null as any)
    const favs = useSelector(favoritesInformation)
    const t = useTranslate();
    const allLines = useSelector(linesState);
    const agencyInfo = useSelector(agencyInformation);

    useEffect(() => {
        async function fetchData() {
            setRenderFavs(await getRenderFavs())
            setRenderAlerts(await getRenderAlerts())
        }
        fetchData()
    },[props.data]);

    const getRenderFavs = async () => {
        let res = []
        let totalLength=0;
        
        for(let element of props.data){
            let agencies = []
            let sorted = sortAlertsByStops(element.stops);
            console.log('Sorted', sorted);
            
            for(let alert of sorted){
                if (favs?.stops.indexOf(alert.stop?.idParada as never)>=0) {
                        totalLength++;
                        console.log('Alert', alert);
                        
                        agencies.push(
                            <TransitAlert time={alert?.fechas}
                            cause={alert.causa}
                            effect={alert.efecto}
                            element={alert.stop}
                            alert={alert}
                            setAlert={props.setAlert}
                            key={alert.idAlerta + Math.random()}
                            />
                        );
                }
            }
            if(agencies.length) {
              const agency: any = agencyInfo.agenciesTransport.find((agen: any) => agen.id === parseInt(element.agencyId));
              res.push(
                <div className={'transit-agency'} key={element}>
                    <div className={'row'} style={{alignItems:'center'}}>
                        <img style={{padding:10}} className={'agency-icon'} src={await VM.icons.getIcon(element.agencyId,'agency')}/>
                        <div className={'subtitle'} style={{textTransform: 'capitalize'}}>{agency?.groupName ? agency?.groupName : agency?.shortName}</div>
                    </div>
                    {agencies}
                </div>)
            }
                
        }

        return (
            <div>
                <div className={'row'} style={{marginTop:15, marginBottom:15}}>
                    <div className={'primaryText'}>
                        {t('favs')}
                    </div>
                    <div className={'secondaryText'} style={{marginLeft:'auto'}}>
                        {totalLength
                            ? <>{totalLength + ' ' + t('stops').toLowerCase()}</>
                            : <>{t('none')}</>
                        }
                    </div>
                </div>
                <div className={"transit-group"}>
                    {res}
                </div>
            </div>)
    }

    function sortLines(alerts: Array<any>) {
        return alerts.sort((a, b) => {
            let dateA = a.date.horaInicio;
            let dateB = b.date.horaInicio;
      
            if (dateA < dateB) {
              return 1;
            }
            if (dateA > dateB) {
              return -1;
            }
      
            if (a.line === undefined || b.line === undefined) {
              return 1;
            }
      
            const lineA: ILine | undefined = allLines.find(
              (line: ILine) => line.id === a.line.idLinea,
            );
            const lineB: ILine | undefined = allLines.find(
              (line: ILine) => line.id === b.line.idLinea,
            );
            if (lineA === undefined || lineB === undefined) {
              return 1;
            }
            let nameA: String | undefined =
              lineA && (lineA as ILine)?.code !== null
                ? (lineA as ILine).code?.toUpperCase()
                : '';
            let nameB: String | undefined =
              lineB && (lineB as ILine)?.code !== null
                ? (lineB as ILine).code?.toUpperCase()
                : '';
            let numberA = typeof nameA === 'string' ? parseInt(nameA) : NaN;
            let numberB = typeof nameB === 'string' ? parseInt(nameB) : NaN;
            if (!isNaN(numberA) && !isNaN(numberB)) {
              return numberA - numberB;
            }
      
            function extractNumber(value: String) {
              // Extraerlos dígitos de la cadena del final
              let match = value.match(/^([^\d]+)(\d+)$/);
      
              return match
                ? {char: match[1], code: parseInt(match[match?.length - 1])}
                : {char: 'error', code: NaN};
            }
      
            let numA = extractNumber(nameA !== undefined ? nameA : '');
            let numB = extractNumber(nameB !== undefined ? nameB : '');
      
            // Si ambos son números, comparar numéricamente
            if (!isNaN(numA.code) && !isNaN(numB.code) && numA.char === numB.char) {
              return numA.code - numB.code;
            }
            if (nameA === undefined || nameB === undefined) {
              return 1;
            }
      
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
      
            // names must be equal
            return 0;
          });
    }

    function sortAlertsByStops(alerts: Array<ITransitAlertParsed>) {
        return alerts.sort((a, b) => {
            let dateA = a.date.horaInicio;
            let dateB = b.date.horaInicio;
      
            if (dateA < dateB) {
              return 1;
            }
            if (dateA > dateB) {
              return -1;
            }
      
            if (a.stop === undefined || b.stop === undefined) {
              return 1;
            }
      
      
            let nameA: String = a.stop.idNombre ?? '';
            let nameB: String = b.stop.idNombre ?? '';
      
            if (nameA === undefined || nameB === undefined) {
              return 1;
            }
      
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
      
            // names must be equal
            return 0;
          });
      }


    const getRenderAlerts = async () => {
        let res = []
        let totalLength=0
        for(let element of props.data){
            
            let agency = []
            let selected = props.selected ? sortAlertsByStops(element.stops)
                    : sortLines(element.lines);
            for(let alert of selected){
                    totalLength++;
                    agency.push(
                        <TransitAlert time={alert?.fechas}
                        cause={alert.causa}
                        effect={alert.efecto}
                        element={props.selected ? alert.stop : alert.line}
                        alert={alert}
                        setAlert={props.setAlert}
                        key={alert.idAlerta + Math.random()}
                        />
                    )
                
            }
            if(agency.length)
                res.push(
                    <div className={'transit-agency'} key={element}>
                        <div className={'row'} style={{alignItems:'center'}}>
                            <img style={{padding:10}} className={'agency-icon'} src={await VM.icons.getIcon(element.agencyId,'agency')}/>
                            <div className={'subtitle'}>{VM.agencies.data[parseInt(element.agencyId)]?.shortName}</div>
                        </div>
                        {agency}
                    </div>)
        }

        return (
            <div>
                <div className={'row'} style={{marginTop:15, marginBottom:15}}>
                    <div className={'primaryText'}>
                        {t('allAlerts')}
                    </div>
                    <div className={'secondaryText'} style={{marginLeft:'auto'}}>
                        {totalLength ? `${totalLength} ${props.selected ? t('stops').toLowerCase() : t('lines').toLowerCase()}` : t('none')}
                    </div>
                </div>
                <div className={"transit-group"}>
                    {res}
                </div>
            </div>)
    }

    return (
        <div className={"transit transit-body"} style={{maxHeight:window.innerHeight - 190}}>
            {props.selected ? renderFavs : null}
            {renderAlerts}
        </div>
    );
}
