export const strings = {
  filters: 'Filters',
  appName: 'Moveuskadi',
  button_delete: 'Delete selected',
  undo: 'Undo',
  remove: 'Delete',
  explore: 'Explore',
  saves: 'Favorites',
  openData: 'Open Data',
  downloadApp: 'Download the moveuskadi mobile application.',
  circulation: 'Circulation',
  account: 'My Account',
  login: 'Log in',
  login2: 'Log in',
  incidence: 'Alerts',
  stopSaved: 'You have saved this stop,',
  transbord: 'Change',
  links:'Links',
  saveStopAlert: 'do you want to set an alert?',
  saveAlert: 'Do you want to set an alert?',
  activateAlerts: 'Activate alerts',
  noThanks: 'No, thank you.',
  saveStopAlertDescription:
    'Activate an alert so that if the stop is affected by some kind of incident, we can notify you. When do you want to receive it?',
  saveLineAlertDescription:
    'Set up an alert so that if the line is affected by an type of incident, we will notify you.',
  saveStopAlertBody:
    'Select when you want to receive service alerts in case of incidents on the saved stops.',
  loginToFav: 'Log in to save as a favourite',
  loginToReport: 'Log in to report an error',
  loginToFavs: 'Log in to save as favourites',
  loginToAlerts: 'Log in to set up an alert',
  loginToFavBody:
    'Log in or create an account to access the full functionality of the application.',
  deleteFavConfirm: 'Do you want to delete this favourite?',
  register: 'Sign up',
  myAccount: 'My Account',
  infoPersonal: 'Personal information',
  whereDoYouGo: 'Where do you want to go?',
  simpleTicket: 'Single ticket',
  activeAlert: 'Activate alerts',
  removeAlert: 'Delete alert',
  alertAdded: 'Alert added',
  alertRemoved: 'Alert deleted',
  password: 'Password',
  passwordConditions:
    'To change your password, you need a minimum of 6 digits, a capital letter, a small letter and a number.',
  man: 'Male',
  woman: 'Female',
  today: 'Today',
  exitTo: 'Departures for',
  another: 'Another',
  setAlias: 'Set an alias',
  alias: 'Alias',
  aliasColon: 'Alias:',
  save: 'Save',
  share: 'Share',
  shareVia: 'Share with',
  linkToShare: 'Link to share',
  copyLink: 'Copy link',
  copiedToClipboard: 'Copying to clipboard',
  downloadMobileApp: 'Download the mobile application',
  goToAppleStore: 'Go to Apple Store',
  goToAccount: 'Go to account',
  goToGooglePlay: 'Go to Google Play Store',
  noBinary: 'Non-Binary',
  ageUnder19: 'between 0 and 19',
  age20to45: 'between 20 and 45',
  age45to65: 'between 45 and 65',
  ageOver65: '65+',
  copyUrl: 'Url copied',
  changePass: 'Change password',
  change: 'Change',
  manageAccount: 'Manage account',
  language: 'Language',
  help: 'Help',
  support: 'Support and help',
  manageNotifications: 'Notification management',
  news: 'News',
  guided: 'Guided',
  guidedNotificationsNotice:
    'If you activate this option, Moveuskadi will send you notifications of the next steps and changes along the guidance process.',
  trafficAlerts: 'Notifications news and alerts',
  general: 'General',
  privacy: 'Privacy policy',
  useConditions: 'Terms of use',
  terms: 'Terms of Service',
  shareApp: 'Share app',
  reviewApp: 'Rate',
  dataAccount: 'Account information',
  charMaxAndMin: '6 characters minimum and 30 characters maximum',
  minNum: 'At least one number',
  and: 'and',
  accept: 'Accept',
  minMayusMin: 'At least one upper and lower case letter',
  noMatch: 'They do not match',
  fillFields: 'Fill in the following fields',
  personalInfo: 'Personal data',
  createAccount: 'Create an account',
  noStationsSaved:
    "You don't seem to have saved any stations or stops in favourites. Choose the ones you use the most and save them so you can access them quickly.",
  meetABit: 'To get us acquainted',
  noTransportSaved: "You don't have any favourite transport",
  noTransportSavedBody:
    'To set up your favourite transports you will have to save them in your account in the favourite transport section.',
  nameComa: 'Name:',
  name: 'Name',
  email: 'Email',
  emailLong: 'Email',
  userName: 'Name of user',
  needANewPass: 'Need a new password?',
  introduceYourEmail: 'Enter the email address you used to create your account.',
  gender: 'Gender',
  ageComa: 'Age:',
  age: 'Age',
  phone: 'Telephone',
  website: 'Website',
  direction: 'Address',
  seeAll: 'See all',
  selectAll: 'Select all',
  selectAllFavorites: 'Select Favorites',
  subscribeNews: 'Subscribe to news',
  selectOpNews: 'Select for which operators you want to receive news notifications.',
  lessInfo: 'See less info',
  moreInfo: 'See more info',
  goThere: 'Getting there',
  notFound: 'Nothing found',
  messageState: 'Status of my comments',
  all: 'All',
  inProcess: 'In progress',
  answered: 'Answered',
  checkSearch: 'Check you have entered the words correctly. Try adding a city, state or postcode.',
  myDirections: 'My addresses',
  directions: 'Addresses',
  searchByCategory: 'Search by category',
  recents: 'Recent',
  useMyLocation: 'My location',
  es: 'Spanish',
  eu: 'Basque',
  en: 'English',
  fr: 'French',
  visualizer_filters: 'Filters',
  visualizer_filtersAll: 'All',
  visualizer_filtersBoth: 'Both',
  visualizer_moreFilters: 'More filters',
  visualizer_message_moreFilters:
    'Select the operators you use most frequently and only those will be displayed on the map.',
  visualizer_filters_empty: 'No filters were loaded, please check your connection.',
  visualizer_filters_motorized: 'Motorisation',
  visualizer_filters_onlySelected: 'Only one',
  visualizer_filters_other: 'Others',
  visualizer_filters_selected: 'selected',
  visualizer_filter_selected: 'selected',
  visualizer_poi: 'Points of interest',
  visualizer_ModosdeTransporte: 'Transport',
  visualizer_operators: 'Operators',
  systemNotifications: 'System notifications',
  systemNotificationsNews:
    'If you activate this option, Moveuskadi will send you notifications about the news to which you have subscribed.',
  systemNotificationsAlerts:
    'If you activate this option, Moveuskadi will send you notifications about transport alerts or incidents for the lines, stops and news to which you have subscribed.',
  systemNotificationsNotice:
    'If you activate this option, Moveuskadi will send you notifications about important changes and errors in the system. We recommend that you keep them active.',
  h: 'h',
  min: 'min',
  accessible: 'Accessible',
  exitNow: 'Leaving now',
  exitAt: 'Departure at',
  exitAtText: 'Depart',
  accountNoActivated: 'Account not activated',
  accountCreated: 'Account created. To activate your account, please check your email.',
  noInternetConnection: 'No internet connection',
  serviceError: 'Service error',
  reviewLoginOrPass: 'Check your username or password',
  seeRoutes: 'See routes',
  stopTime: 'What time do you want to leave?',
  accountActivated: 'Account activated',
  accountActivatedDes: 'Your account has been successfully created and activated!',
  accountNoActivatedDes:
    'There was a problem creating and activating your account. Please try again later.',
  arriveAt: 'Arrival',
  preferences: 'Preferences',
  other: 'Other',
  confirm: 'Confirm',
  leaveSuggestion: 'Post a suggestion',
  suggestionNotice:
    'We are constantly striving to improve your experience. Therefore, we would like to know which features you like the most and which ones could improve.',
  commentStateNotice:
    'You will be able to see the status of all the suggestions and reports you have posted.',
  report: 'Report',
  timeSoonThanBefore: 'Earlier than the previous stop',
  timeSoonThanBeforeBody:
    'The selected time is before a time of a previous stop. Please select a later time.',
  reportProblem: 'Report an incident',
  reportSystemError: 'Report a system error',
  reportOperator: 'Report an operator',
  reportAnError: 'Report an error',
  reportStop: 'Report stop',
  reportStopNotice: 'Choose the reason you want to report the next stop.',
  reportWrongAddress: 'Incorrect address',
  reportStopData: 'Incorrect stop data',
  reportStopState: 'Stop status',
  reportLine: 'Report line',
  reportLineNotice: 'Choose the reason why you want to report the next line.',
  reportDataError: 'Incorrect data',
  reportTimeError: 'Error in run times',
  reportPlanning: 'Report planning',
  reportPlanningNotice: 'Choose the reason why you want to report the following planning.',
  reportDoesntComplyWithPreferences: 'Does not meet preferences',
  reportNoResults: 'No results found',
  reportAlternativeRoutes: 'There are alternative routes',
  reportRoute: 'Report route',
  reportRouteNotice: 'Choose the reason why you want to report planning',
  reportIndicationsError: 'Error in instructions',
  sharePlanning: "Check the follow route's information",
  leaveComment: 'Post a comment',
  sendComment: 'Send comment',
  send: 'Send',
  destination: 'Destination',
  origin: 'Origin',
  tomorrow: 'Tomorrow',
  nextStops: 'Upcoming departures',
  searchADirection: 'Find an address',
  suggestion: 'Suggestion',
  planificationReport: 'Planning report',
  stopReport: 'Report stop',
  lineReport: 'Report line',
  answer: 'Answer',
  filterNoResult:
    'Select another quick filter or recalculate the planning with a new configuration.',
  firstExit: 'First departure',
  lastExit: 'Last departure',
  component_loading: 'Loading',
  none: 'None',
  favs: 'Favourites',
  allAlerts: 'All alerts',
  lines: 'Lines',
  transit_lines: 'Lines',
  transit_stops: 'Stops',
  start: 'Start:',
  end: 'End:',
  cantAddStops: 'No more stops can be added',
  maximumNumberStops: 'The maximum number of stops has been reached.',
  stationsQuant: 'Stops',
  yourLocation: 'Your location',
  accessible_routes: 'Accessible Routes',
  fav_transports: 'Favourite transport',
  fast_routes: 'Fastest routes',
  eco_routes: 'Cheapest routes',
  transport_modes: 'Modes of transport',
  monitor_types: 'Type of motorisation',
  operators: 'Operators',
  route_types: 'Types of route',
  fastest_route: 'FASTEST ROUTE',
  eco_route: 'CHEAPEST ROUTE',
  walk_routes: 'Walking less routes',
  walk_route: 'WALKING LESS ROUTE',
  routes: 'Itineraries',
  places: 'Places',
  ubis: 'Locations',
  favorites: 'Favourites',
  stations: 'Stations',
  stops: 'Stops',
  stops_route: 'Stops',
  stop: 'Stop',
  stopSaved2: 'Stop saved',
  stopRemoved: 'Stop deleted',
  directionSaved: 'Address saved',
  directionRemoved: 'Address deleted',
  poiSaved: 'Point of interest saved',
  poiRemoved: 'Point of interest removed',
  planSaved: 'Planning saved',
  planRemoved: 'Planning deleted',
  stopsAndStations: 'Stops and stations',
  cancel: 'Cancel',
  next: 'Next',
  myTransport: 'My transport',
  descriptionMyTransport:
    'Select the transport you use most frequently to get quick access from the map.',
  forgotPass: 'I forgot my password',
  configAlert: 'Configure alert',
  pois: 'Points of interest',
  logout: 'Log out',
  close: 'Close',
  alertDetail: 'Notification detail',
  youAreBack: "You're back!",
  alreadyHaveAccount: 'I already have an account',
  errorLogin: 'Error logging in',
  notAccount: 'I do not have an account.',
  myFavTransp: 'My favourite transport',
  date_day: 'A day',
  date_days: 'days',
  date_difference: 'ago',
  date_weeks: 'wweks',
  date_hours: 'hours',
  date_minute: 'min',
  minute: 'minute',
  minutes: 'minutes',
  date_month: 'A month',
  date_week: 'One week',
  zero_min: '0 min',
  newsDetails: 'Details of news',
  moreInf: 'More information',
  seeMore: 'See more',
  visit: 'Visit',
  noNews: 'No news found',
  edit: 'Edit',
  otherPois: 'Other points of interest',
  delete: 'Delete',
  newPass: 'New password',
  oldPass: 'Old password',
  passNew: 'New password',
  repeatPassNew: 'Repeat new password',
  home: 'Home',
  work: 'Work',
  add: 'Add',
  addHome: 'Add home',
  addWork: 'Add work',
  addDirection: 'Add address',
  update: 'Update',
  setDirection: 'Set the address',
  createAlert: 'Create alert',
  trainStation: 'Railway station',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  octuber: 'October',
  november: 'November',
  december: 'December',
  from: 'From',
  until: 'to',
  untilThe: 'Until',
  at: 'at',
  success: 'Success',
  recoveryEmailSent:
    'If there is already user with the email address you entered, we will send you an email to recover your password.',
  resetData: 'Account data has been reset',
  accountDataSaved: 'Account data has been updated',
  accountNotRegistered: 'Your email is not registered',
  userExists: 'A user with this email already exists',
  activateYourAccount: 'Activate your account in order to log in',
  loginSuccess: 'You have been successfully logged in',
  logoutSuccess: 'You have been successfully logged out',
  intermediateStop: 'Intermediate Stop ',
  noLineData: "We don't have information about this line",
  stationOf: ' station',
  stopOf: ' stop',
  removeAccount: 'Delete account',
  removeAccountNotice:
    'Are you sure you want to delete your Moveuskadi account? If you confirm the cancellation, you will lose all the information stored in the system (favourites, alerts, messages, etc.) and it will not be possible to recover it.',
  walk: 'Walking',
  bus: 'Bus',
  subway: 'Subway',
  funicular: 'Cable car',
  tranvia: 'Tram',
  rentalBike: 'Rental bike',
  bike: 'Bicycle',
  train: 'Train',
  bikes: 'Bicycles',
  capacity: 'Capacity',
  markersGroup: 'Marker Group',
  leaveTitle:'Put a subject',
  reportIncidence: 'Report an incidence',
  stopIncidence: 'Incidence on stop',
  lineIncidence: 'incidence on line',
  planIncidence: 'Incidence on route',
  operatorIncidence: 'Incidence on operator',
  systemIncidence: 'System/application incident',
  bikeStation: 'Bike Station',
  poiContainer: 'Points of interest container',
  stopContainer: 'Stop container',
  container:'Marker container',
  moveInformative:'The content of Moveuskadi is exclusively informative. The provider of the data is the corresponding transport operator. The Basque Government will not be liable for any consequence, damage or harm that may arise from the use of said information.',
  visualizer_filter_markers_pois: 'Hide points of interest',
  stopSchedule: 'Stop Schedule',
  searchEmptyResults: 'No results found',
  searchMoreResults: 'Load more',
  maxWalkDistance_title: 'Maximum walking time',
  maxWalkDistance_placeholder: 'Time in minutes',
  menu_lines_search: 'Line search',
  menu_line_filter_empty: 'No line has been found.',
  menu_line_selector_empty: 'No operator has been selected.',
  select_operator_placeholder: 'Select an operator',
  menu_lines: 'List of lines',
  transit_news: 'News and alerts',
  incidence_line: 'Line Alerts',
  incidence_stop: 'Stop Alerts',
  filters_newsTransit_operators: 'Filter operators',
  filters_newsTransit_operators_desc: 'Select the operators for which you want to see news and alerts.',
  stop_time_real_late: 'minutes delay',
  stop_time_real_ahead: 'minutes ahead',
  stop_time_real_late_min: 'minute delay',
  stop_time_real_ahead_min: 'minute ahead',
  stop_time_real_theory: 'Scheduled',
  favorites_empty_plansMessage:
    "You don't seem to have saved any itineraries. Add your frequent routes and save them for quick access.",
  favorites_empty_poisMessage:
    "You don't seem to have saved any points of interest. Add them for quick access.",
  goToFav: 'Go to favorites',
  subscriptions_empty_newsMessage:
    "You don't seem to have activated any news agencies. Activate alerts for the ones you are most interested in.",
  subscriptions_empty_linesMessage:
    "You don't seem to have activated alerts for any transport lines, Activate the alerts for the ones you are most interested in.",
  subscriptions_empty_stopsMessage:
    "You don't seem to have activated alerts for any stops. Activate alerts for the ones that interest you.",
  affected_lines: 'Affected lines',
  affected_stops: 'Affected stops',
  fares: 'Fares',
  lines_near_stop: 'Nearby lines',
  lines_near_marker: 'Nearby lines',
  stop_time_real_in_time: 'In time',
  visualizer_closestStopsLines: 'Nearby stops and lines',
};
