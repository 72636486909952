import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';

export default function AccountItem(props: any, ref: any) {
  const theme = useTheme();
  const RightArrow = R.drawables.commons.rightArrow;
  const Icon = props.icon;
  const responsive = useResponsive();

  const styles = {
    content: {},
    title: {
      color: theme.colors.neutralBlack,
      fontWeight: 600,
      fontSize: '14px'
    },
    row: {
      //   paddingInline: '10px',
      display: 'flex',
      paddingBottom: '10px',
      //   marginBottom: '16px',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };

  return (
    <div
      style={{ ...styles.content, ...props.style }}
      className="account-body-menu-item"
      onClick={() => props.onClick()}>
      <div style={styles.row}>
        <Icon style={{ marginRight: '10px', marginLeft: '10px', minWidth: '20px' }} fill={theme.colors.neutralBlack} />
        {responsive.isBig ? (
          <>
            <div style={styles.title}>{props.txt}</div>
            <RightArrow
              style={{ marginLeft: 'auto', minWidth: '10px' }}
              fill={theme.colors.neutralBlack}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}
